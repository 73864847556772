import {
  SectionNames,
  AudioNames
} from '../../types'
import { tutorialDefaultStates } from './TutorialDefaultState'
import store from '@/store'
import {
  audioManager,
  MobileDetector,
  tutorialManager,
  TutorialMessageColors
} from '@powerplay/core-minigames'

/**
 * Informacie pre UI priapdne kontrolne prvky na zmenu UI roznych taskov
 */
export class TutorialUIChange {

  /** Meno prave beziacej sekcie */
  private actualSectionName: SectionNames | undefined

  /** Samotny stav ktory chceme vo roznych krokoch tutorialu */
  private uiState = {
    [SectionNames.handCheck]: () => {

      store.commit('BlurState/SET_IS_ACTIVE', true)
      this.setMobile(true)
      // dame prec loading
      const loadingState = store.getters['LoadingState/getLoadingState']
      const newState = { ...loadingState,
        showLoading: false }
      store.commit('LoadingState/SET_STATE', newState)

    },
    [SectionNames.startSection]: () => {

      this.resetTypeWrite()
      this.setMobile(false)
      this.setMessage(true, 'tutorialText5-1')
      this.setAnne(true)

      // dame prec loading (pre web)
      const loadingState = store.getters['LoadingState/getLoadingState']
      const newState = { ...loadingState,
        showLoading: false }
      store.commit('LoadingState/SET_STATE', newState)
      audioManager.play(AudioNames.commentIntro)

    },
    [SectionNames.startSectionSecond]: () => {

      this.resetTypeWrite()
      const mobile = MobileDetector.isMobile()
      this.setMessage(true, 'tutorialText5-2', undefined, undefined, !mobile)

      this.setAnne(!mobile, true)

    },
    [SectionNames.startSectionThird]: () => {

      this.resetTypeWrite()
      // zmenime store - podla storeu sa zmeny UI
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.afterRunUpSection]: () => {

      this.resetTypeWrite()
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.startRunningSection]: () => {

      this.resetTypeWrite()
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.startOfSecondCurveSection]: () => {

      this.resetTypeWrite()
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.finishSection]: () => {

      this.resetTypeWrite()
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.endSection]: () => {

      this.resetTypeWrite()
      this.setMessage(false, '')
      this.setAnne(false)

    }
  }

  /** Referencia na vue */
  // eslint-disable-next-line
    private vm: any;

  /**
   * Nastavenie Vue referencie
   * @param vm - vue referencia
   */
  public registerVM(vm: unknown): void {

    this.vm = vm

  }

  /**
   * Zena UI podla aktualnej sekcie
   * @param sectionName - Meno aktualnej sekcie
   */
  private changeUi(sectionName: SectionNames): void {

    this.uiState[sectionName]()

  }

  /**
   * Resetnutie typewrite
   */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    store.commit('TutorialCoreState/SET_TYPE_WRITER', true)

  }

  /**
   * Inicializovanie
   */
  public init(): void {

    // Ak bude treba.

  }

  /**
   * Hlavna logika ui zmeny
   */
  private tutorialUILogic(): void {

    const sectionName = tutorialManager.getActualSectionName() as SectionNames
    if (sectionName && sectionName !== this.actualSectionName) {

      this.changeUi(sectionName)
      this.actualSectionName = sectionName

    }

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    tutorialDefaultStates.update()
    this.tutorialUILogic()

  }

  /**
   * Nastavenie Anny
   * @param showAnne - Ci sa ma zobrazit
   * @param isRight - Ci sa ma umiestnit vpravo
   * @returns this
   */
  public setAnne(
    showAnne: boolean,
    isRight = store.getters['GameSettingsState/isLeft']
  ): this {

    store.commit('TutorialState/SET_ANNE', {
      showAnne,
      isRight
    })

    return this

  }

  /**
   * Nastavenie hlasky pre tutorial
   * @param showMessage - ci sa ma zobrazit hlaska
   * @param message - text
   * @param color - farba
   * @param yellowTextSpecial - specialny text klikni na XYZ
   * @returns this
   */
  public setMessage(
    showMessage: boolean,
    message = '',
    color = TutorialMessageColors.blank,
    yellowTextSpecial = '',
    offset = false
  ): this {

    let yellowText = this.vm.$i18n.t('clickToContinueTemp')
    if (MobileDetector.isMobile()) yellowText = this.vm.$i18n.t('tapToContinue')
    if (yellowTextSpecial) {

      yellowText = this.vm.$i18n.t('tapToButton').replace(
        '[BUTTON]',
        this.vm.$i18n.t(yellowTextSpecial)
      )

    }

    store.commit(
      'TutorialState/SET_MESSAGE',
      {
        showMessage,
        message,
        color,
        yellowText,
        offset,
        yellowTextSpecial: yellowTextSpecial !== ''
      }
    )
    return this

  }

  /**
   * Nastavenie mobilnej uvodnej obrazovky
   * @param show - Ci ma byt zobrazena
   * @returns this
   */
  public setMobile(show: boolean): this {

    store.commit('TutorialState/SET_MOBILE', {
      show
    })
    return this

  }

}

export const tutorialUIChange = new TutorialUIChange()
