import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface TuckState {
  isTuck: boolean
}

const initialState = () => ({
  isTuck: false
})

const tuckState: Module<TuckState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getInputsState: (moduleState: TuckState) => moduleState,
    isTuck: (moduleState: TuckState) => moduleState.isTuck
  },

  mutations: {
    RESET: (moduleState: TuckState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: TuckState, newState: TuckState) => {

      moduleState.isTuck = newState.isTuck

    }
  }
}

export default tuckState
