/** Interface pre manazer faz v discipline */
export interface DisciplinePhaseManager {

  preparePhase(): void;
  startPhase(): void;
  finishPhase(): void;
  update(): void;
  setFinishPhaseTween(): void;

}

/** Disciplinove fazy */
export enum DisciplinePhases {

  preStart = 0,
  start = 1,
  runUp = 2,
  running = 3,
  finish = 4,
  end = 5

}
