import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import type { StartPhaseManager } from '@/app/phases/StartPhase/StartPhase'
import {
  AudioNames,
  DisciplinePhases,
  PlayerAnimationsNames,
  PlayerTypes
} from '@/app/types'
import {
  CallbackAnimationTypes,
  corePhasesManager,
  gsap,
  modes,
  playersManager,
  trainingManager
} from '@powerplay/core-minigames'
import { player } from '.'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import {
  playerAnimationConfig,
  runningPhaseConfig,
  startPhaseConfig,
  velocityConfig
} from '@/app/config'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'

/**
 * Dedikovany manazer animacii pre hraca.
 * Nie je to najkrajsi pattern, lebo priamo berie hracove publik atributy... ale sorry not sorry
 */
export class PlayerAnimationManager {

  /** Prave beziaca animacia */
  public animationRunning = PlayerAnimationsNames.prepare

  /** Ci je aktivny starting state */
  private startingStateActive = false

  /** Ci je aktivny starting state */
  private skatingStateActive = false

  /** Ci je aktivny end state */
  private endStateActive = false

  /** Aktualny cyklus skatingu */
  private actualSkatingLoop = 0

  /** Aktualny cyklus skating po shooting outre */
  private actualShootingSkatingLoop = 0

  /** ci sme nastavili konecnu animaciu */
  public isEndEmotionSet = false

  /** ci sa nachadza v zakrute */
  private isTurning = false

  /** ci uz bola prva zakruta */
  private wasFirstTurn = false

  /** vaha flat animacie */
  private flatWeight = 0

  /** Pocitadlo frameov pre zmenu rychlosti z animacie start do flat */
  private framesCounterAnimationCrossfade = 0

  /** Ci je aktivna akurat animacia run up pri false starte */
  private runUpFalseStartAnimationActive = false

  /**
   * Spustenie startovacej animacie
   */
  private startAnimation(): void {

    player.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.start,
      CallbackAnimationTypes.loop,
      () => {

        this.changeAnimationSpeed(startPhaseConfig.animationSpeed)

        if (this.startingStateActive) return
        player.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.start,
          CallbackAnimationTypes.loop
        )
        this.startSkatingAnimation()
        // console.log('START ANIMATION -- end')

      }
    )
    player.animationsManager.crossfadeTo(PlayerAnimationsNames.start, 0.2, true, false)

    const crossfadeAnimationSpeed = gsap.to({}, {
      duration: 0.2,
      onUpdate: () => {

        const progress = crossfadeAnimationSpeed.progress()
        const startSpeed = 1
        const speed = startSpeed + (startPhaseConfig.animationSpeed - startSpeed) * progress
        this.changeAnimationSpeed(speed)

      }
    })

    // player.animationsManager.changeTo(PlayerAnimationsNames.start)

  }

  /**
   * Spustene false startovacej animacie
   */
  private falseStartAnimation(): void {

    player.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.falseStart,
      CallbackAnimationTypes.end,
      () => {

        this.runUpFalseStartAnimationActive = false

        player.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.falseStart,
          CallbackAnimationTypes.end
        )

        player.animationsManager.crossfadeTo(
          PlayerAnimationsNames.falseStartEnd,
          playerAnimationConfig.defaultCrossfadeTime,
          true,
          false
        )

      }
    )
    this.runUpFalseStartAnimationActive = true
    player.animationsManager.changeTo(PlayerAnimationsNames.falseStart)

  }

  /**
   * Start animacie prestart
   */
  private startPrestartAnimation(): void {

    console.log('launching')
    player.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.prestart,
      CallbackAnimationTypes.end,
      () => {

        const startPhaseManager = disciplinePhasesManager
          .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager

        player.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.prestart,
          CallbackAnimationTypes.end
        )
        this.resetAnimationSpeed()

        if (modes.isTutorial() && tutorialFlow.failedStartCount === 0) return
        startPhaseManager.launchSystem()
        console.log('starting launch system')

      }
    )

    player.animationsManager.changeTo(PlayerAnimationsNames.prestart)

  }

  /**
   * Zmena rychlosti animacii
   * @param speed - Nova rychlost animacii
   */
  private changeAnimationSpeed(speed: number) {

    player.animationsManager.setSpeed(speed)

  }

  /**
   * Reset rychlosti animacii
   */
  private resetAnimationSpeed() {

    player.animationsManager.resetSpeed()

  }

  /**
   * Riesenie veci pre sprint
   */
  private sprintSituation(): void {

    // todo

  }

  /**
   * Riesenie veci pre klasicky beh
   * @returns True, ak ide o neutralnu situaciu
   */
  private neutralSituation(): boolean {

    return (!player.isCrouching && !player.isSprinting && !player.isSkating)

  }

  /**
   * Riesenie veci pre specialne situacie
   * @returns True, ak ide o specialnu situaciu
   */
  private specialSituation(): boolean {

    const isGameStart = this.isGameStartState()
    const isStarting = this.isStartingState()
    const isSkating = this.isSkatingState()
    const isEnd = this.isEndState()

    return isGameStart || isStarting || isSkating || isEnd

  }

  /**
   * Riesenie veci pre game start stav
   * @returns True, ak ide o dany stav
   */
  private isGameStartState(): boolean {

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.preStart) return true

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.start) {

      const discipline = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager

      if (discipline.getCameraInPostIntroState() &&
                this.animationRunning !== PlayerAnimationsNames.prestart
      ) {

        this.startPrestartAnimation()
        this.animationRunning = PlayerAnimationsNames.prestart

      }
      return true

    }

    return false
    /*
     * return (disciplinePhasesManager.getActualPhase() === DisciplinePhases.preStart ||
     * disciplinePhasesManager.getActualPhase() === DisciplinePhases.start)
     */

  }

  /**
   * Riesenie veci pre starting stav
   * @returns True, ak ideo o dany stav
   */
  private isStartingState(): boolean {

    if (!this.startingStateActive && player.isStarting) {

      this.startingStateActive = true

      if (player.isFailed) {

        this.falseStartAnimation()

      } else {

        this.startAnimation()

      }

      // this.changeAnimationSpeed(playerAnimationConfig.animationSpeedForSprint)

    }

    if (player.isStarting && (this.runUpFalseStartAnimationActive || !player.isFailed)) {

      const animation = this.runUpFalseStartAnimationActive ?
        PlayerAnimationsNames.falseStart :
        PlayerAnimationsNames.start

      audioHelper.manageSkateHitIceSound(
        AudioNames.skateStartLeft,
        AudioNames.skateStartRight,
        player.animationsManager.getAnimationActualTime(animation),
        PlayerTypes.player
      )

    }

    return player.isStarting

  }

  /**
   * Riesenie veci pre skating stav
   * @returns True, ak ideo o dany stav
   */
  private isSkatingState(): boolean {

    if (!this.skatingStateActive && player.isSkating) {

      this.startingStateActive = false

    }

    if (player.isSkating && !this.isEndEmotionSet) {

      audioHelper.manageSkateHitIceSound(
        this.isTurning ? AudioNames.skateCurveLeft : AudioNames.skateLeft,
        this.isTurning ? AudioNames.skateCurveRight : AudioNames.skateRight,
        player.animationsManager.getAnimationActualTime(PlayerAnimationsNames.flatPlane),
        PlayerTypes.player
      )

    }

    return player.isSkating

  }

  /**
   * Start skatingu
   */
  private startSkatingAnimation(): void {

    this.framesCounterAnimationCrossfade = 0
    this.animationRunning = PlayerAnimationsNames.flatPlane

    // musime este nastavit rovnaky cas flat animacii podla startu
    player.animationsManager.manualyUpdateTimeByPercent(
      PlayerAnimationsNames.flatPlane,
      player.animationsManager.getAnimationPercentageDone(PlayerAnimationsNames.start)
    )

  }

  /**
   * Riesenie veci pre end stav
   * @returns True, ak ide o dany stav
   */
  private isEndState(): boolean {

    if (!this.endStateActive && player.isEnd) {

      this.endStateActive = true

      const emotionAnimation = this.getEndEmotion()
      this.animationRunning = emotionAnimation

      player.animationsManager.addAnimationCallback(
        emotionAnimation,
        CallbackAnimationTypes.end,
        () => {

          player.animationsManager.removeAnimationCallback(
            emotionAnimation,
            CallbackAnimationTypes.end
          )

          this.resetAnimationSpeed()
          // this.startStop1Animation(0.2)

        }
      )

      this.resetAnimationSpeed()
      player.animationsManager.setWeight(PlayerAnimationsNames.turn, 0)
      player.animationsManager.setWeight(PlayerAnimationsNames.flatPlane, 0)

      if (emotionAnimation === PlayerAnimationsNames.neutral) this.startNeutralAnimation()
      if (emotionAnimation === PlayerAnimationsNames.happy) this.startHappyAnimation()

    }
    return player.isEnd

  }

  /**
   * Spustenie animacie neutral
   */
  private startNeutralAnimation(): void {

    player.animationsManager.changeTo(PlayerAnimationsNames.neutral)

  }

  /**
   * Spustenie animacie happy
   */
  private startHappyAnimation(): void {

    player.animationsManager.changeTo(PlayerAnimationsNames.happy)

  }

  /**
   * Samotna logika
   */
  private animationLogic(): void {

    const isSpecialSituation = this.specialSituation()

    if (!isSpecialSituation) {

      const isNeutralState = this.neutralSituation()
      if (!isNeutralState) this.sprintSituation()

    }

    if (
      [
        PlayerAnimationsNames.flatPlane, PlayerAnimationsNames.turn
      ].includes(this.animationRunning) &&
            !this.endStateActive
    ) {

      this.manualChangingTurnFlat()

      const { boundary, valueUnderboundary } = velocityConfig.animationSpeedByVelocity

      // najskor si iba vypocitame, aka rychlost by mala byt
      const speed = (player.speedManager.percentSpeed < boundary) ?
        valueUnderboundary :
        player.speedManager.percentSpeed

      let finalSpeed = speed

      // na zaciatku ked ideme z animacie start do flat, potrebujeme crossfade aj pre rychlost
      if (this.flatWeight < 1 && !this.wasFirstTurn) {

        const { animationSpeed } = startPhaseConfig
        const maxFrames = runningPhaseConfig.manualCrossfadeAnimationSpeed * 100
        this.framesCounterAnimationCrossfade++
        const perc = this.framesCounterAnimationCrossfade / maxFrames
        finalSpeed = animationSpeed - ((animationSpeed - speed) * perc)

      }

      this.changeAnimationSpeed(finalSpeed)

    }

  }

  /**
   * Update metoda volana v move metode velocity manazera
   */
  public update(): void {

    this.animationLogic()

  }

  /**
   * Prepinanie medzi animaciami turn a flatPlane
   * @param value - toggle value
   */
  public toggleTurn(value: boolean): void {

    let animation = PlayerAnimationsNames.flatPlane
    this.isTurning = false
    this.wasFirstTurn = true
    if (value) {

      animation = PlayerAnimationsNames.turn
      this.isTurning = true

    }
    console.warn('turn toggle')
    this.animationRunning = animation
    player.animationsManager.setWeight(PlayerAnimationsNames.start, 0)

  }

  /** manualne ovladanie weight animacii zakruty a roviny */
  private manualChangingTurnFlat(): void {

    const changingSpeed = runningPhaseConfig.manualCrossfadeAnimationSpeed

    if (this.isTurning) {

      this.flatWeight -= changingSpeed
      if (this.flatWeight < 0) this.flatWeight = 0

    } else {

      this.flatWeight += changingSpeed
      if (this.flatWeight > 1) this.flatWeight = 1

    }

    const anim = this.wasFirstTurn ? PlayerAnimationsNames.turn : PlayerAnimationsNames.start
    player.animationsManager.setWeight(anim, 1 - this.flatWeight)
    player.animationsManager.setWeight(PlayerAnimationsNames.flatPlane, this.flatWeight)

  }

  /**
   * Vratenie konecnej emocie
   * @returns Emocia
   */
  private getEndEmotion = (): PlayerAnimationsNames => {

    this.isEndEmotionSet = true

    if (modes.isTrainingMode()) return this.getEndEmotionTraining()

    const time = playersManager.players[0].resultsArr?.[
      corePhasesManager.disciplineActualAttempt - 1
    ].main

    if (
      playersManager.getPlayerActualPosition() <= 3 ||
            (time !== undefined && time <= playersManager.getPlayer().personalBest)
    ) {

      return PlayerAnimationsNames.happy

    }

    return PlayerAnimationsNames.neutral

  }

  /**
   * emocia pre trening
   * @returns emocia
   */
  private getEndEmotionTraining = (): PlayerAnimationsNames => {

    const tasks = trainingManager.getTrainingTasks()
    const sum = tasks.reduce((prev, current) => prev + current.value, 0)
    const average = sum / tasks.length

    let emotion = PlayerAnimationsNames.neutral

    if (average > 0.9) emotion = PlayerAnimationsNames.happy

    return emotion

  }

  /**
   * reset
   */
  public reset(): void {

    this.skatingStateActive = false
    this.startingStateActive = false
    this.endStateActive = false
    this.runUpFalseStartAnimationActive = false
    this.animationRunning = PlayerAnimationsNames.prepare
    player.animationsManager.changeTo(PlayerAnimationsNames.prepare)
    player.animationsManager.removeAnimationCallback(
      PlayerAnimationsNames.start,
      CallbackAnimationTypes.loop
    )
    this.isEndEmotionSet = false

  }

}

export const playerAnimationManager = new PlayerAnimationManager()
