<template>
  <section class="positioner">
    <section
      v-if="buttonsVisible && showImpulseInputUI"
    >
      <movement-sides
        :disabled="isDisabled"
        ignore-safe-zones
        @position="changeMovement"
        @end-contact="endContact"
      />
    </section>
    <section
      v-if="buttonsVisible && gamePhaseState.showUi && !showSpeedBar"
    >
      <section
        v-if="!gameSettingsState.isLeft"
        class="flex justify-between"
      >
        <section v-show="!showJoystick" />
        <section class="relative">
          <!-- <action-button
            v-if="!movementButtonsActive"
            class="bottom-right"
          /> -->
        </section>
      </section>
      <section
        v-else
        class="flex justify-between"
      >
        <section class="relative">
          <!-- <action-button
            v-if="!movementButtonsActive"
            class="bottom-left"
          /> -->
        </section>
      </section>
    </section>
    <section
      v-if="showSpeedBar || startPhaseState.startable"
      class="action-sides-container"
    >
      <div
        class="left-side"
        :style="{transform: transformCoef}"
      >
        <mobile-button
          class="left-side-button"
          type="speed-up-skating-l"
          :is-scaled="false"
          @mousedown="changeMovement(-1)"
          @mouseup="endContactZeroed()"
          @touchstart="changeMovement(-1)"
          @touchend="endContactZeroed()"
        />
      </div>
      <div
        class="right-side"
        :style="{transform: transformCoef}"
      >
        <mobile-button
          class="right-side-button"
          type="speed-up-skating-r"
          :is-scaled="false"
          @mousedown="changeMovement(1)"
          @mouseup="endContactZeroed()"
          @touchstart="changeMovement(1)"
          @touchend="endContactZeroed()"
        />
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import {
  MovementSides,
  MobileButton,
  WindowAspect
} from '@powerplay/core-minigames-ui'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MobileInputs',
  components: {
    MovementSides,
    MobileButton
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
      isDisabled: 'InputsState/getDisabled',
      buttonsVisible: 'InputsState/getIsVisible',
      showJoystick: 'ActionButtonState/getShowJoystick',
      startPhaseState: 'StartPhaseState/getStartPhaseState',
      gamePhaseState: 'GamePhaseState/getGamePhaseState',
      movementButtonsActive: 'MovementButtonsState/getMovementButtonsActive',
      actionDisabled: 'InputsState/getIsActionDisabled',
      gameSettingsState: 'GameSettingsState/getGameSettingsState',
      showSpeedBar: 'RunUpBarState/getShowBar',
      showImpulseInputUI: 'ImpulseInputState/isShown',
      posX: 'MovementState/getPositionX'
    }),
    transformCoef() {

      return `scale(${ this.scaleCoef })`

    }
  },
  methods: {
    endContact(positionX: number) {

      let newPosX = 0
      if (this.posX == 0) {

        newPosX = positionX

      }
      this.$store.commit('MovementState/SET_POSITION_X', newPosX)
      this.$store.commit('MovementState/SET_IS_PRESSED', false)

    },
    endContactZeroed() {

      this.$store.commit('MovementState/SET_POSITION_X', 0)
      this.$store.commit('MovementState/SET_IS_PRESSED', false)

    },
    changeMovement(positionX: number) {

      this.$store.commit('MovementState/SET_POSITION_X', positionX)
      this.$store.commit('MovementState/SET_IS_PRESSED', true)

    }
  }
})

</script>

<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.buttons-position {
  position: absolute;
  top: -60px;
  left: 0;
  width: 100%;
  height: 200px;
}

.action-sides-container {
    height: 580px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;

    &.disabled {
        opacity: 0.7;
    }

    .power-mobile-button{
        .button-element{
            .image{
                height: 100px;
                width: 100px;
                background-size: cover;
                left: 50%;
                margin-left: -50px;
            }
        }
    }

    .left-side{
        display: flex;
        position: fixed;
        left: 0;
        bottom: 0;
        transform-origin: bottom left;

        .left-side-button {
            .button-element {
                .image {
                    margin-left: -30px;
                }
            }
        }
    }

    .right-side{
        display: flex;
        position: fixed;
        right: 0;
        bottom: 0;
        transform-origin: bottom right;

        .right-side-button {
            .button-element {
                .image {
                    margin-left: 20px;
                }
            }
        }
    }

}
.bottom-right {
  transform-origin: bottom right;
}
.bottom-left {
  transform-origin: bottom left;
}
</style>
