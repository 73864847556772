<template>
  <div
    class="time-keeper-laps-container"
    :style="[transform, {transformOrigin: '0 0'}]"
  >
    <div
      :style="{
        width: '383px',
        height: '70px'}"
    >
      <blue-box
        v-if="showFinalLap"
        side="left"
        :is-scaled="false"
        :style="{
          width: '383px',
          height: '70px',
          display: 'flex',
          'flex-direction': 'row',
          'justify-content': 'flex-start',
          'margin-bottom': '20px',
          background: `linear-gradient(80deg, rgba(24,81,127,0.9) 0%,
        rgba(6,17,35,0.8) 65%, rgba(6,17,35,0.7) 85%, rgba(6,17,35,0) 100%)`
        }"
      >
        <img
          :src="`${pathAssets}/ui/ICO_LAP_TIME.png`"
          style="margin: 0 15px"
          alt="lap"
        >
        <shrinking-text-box
          :text="$t('utFinalLap')"
          :font-size="43"
          :width="300"
          :height="70"
          class="lap-time-text"
        />
      </blue-box>
    </div>
    <record-box
      v-if="showFinalLap && !showSplitTimes"
      yellow-text="WR"
      :blue-text="wrFormated"
      style="margin-bottom: 2px"
      :is-scaled="false"
    />
    <record-box
      v-if="showFinalLap && !showSplitTimes"
      yellow-text="PB"
      :blue-text="pb"
      :is-scaled="false"
    />
    <template
      v-if="showSplitTimes"
    >
      <time-keeper-laps-leader
        v-if="showLeader"
        :is-scaled="false"
        :text="$t('utLeader')"
        :time="bestTime"
        style="margin-bottom: 2px"
      />
      <time-keeper-laps
        v-if="players[0]"
        style="margin-bottom: 2px"
        :show-diff="players[0].showDiff"
        :player="players[0]"
        :class="players[0].background"
        :is-scaled="false"
      />
      <time-keeper-laps
        v-if="players[1]"
        :show-diff="players[1].showDiff"
        :player="players[1]"
        :class="players[1].background"
        :is-scaled="false"
      />
    </template>
  </div>
  <div
    v-if="showFinishSplit"
    class="finish-split"
    :style="[transform, {transformOrigin: '0 100%'}]"
  >
    <time-keeper-laps
      v-if="players[2]"
      style="margin-bottom: 2px"
      :show-diff="players[2].showDiff"
      :player="players[2]"
      :class="players[2].background"
      :is-scaled="false"
    />
    <time-keeper-laps
      v-if="players[3]"
      :show-diff="players[3].showDiff"
      :player="players[3]"
      :class="players[3].background"
      :is-scaled="false"
    />
  </div>
</template>

<script lang="ts">
import {
  TimeKeeperLaps,
  TimeKeeperLapsLeader,
  BlueBox,
  ShrinkingTextBox,
  RecordBox,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'
import { pathAssets } from '@/globals/globalvariables'
import { timeManager } from '@powerplay/core-minigames'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TimeKeeperLapsComponent',
  components: {
    TimeKeeperLaps,
    BlueBox,
    ShrinkingTextBox,
    TimeKeeperLapsLeader,
    RecordBox
  },
  mixins: [WindowAspect],
  data() {

    return {
      pathAssets
    }

  },
  computed: {
    ...mapGetters({
      timeState: 'TimeState/getTimeState',
      splitTimeData: 'SplitTimeState/getSplitTimeData',
      actualTime: 'SplitTimeState/getActualTime',
      showSplitTimes: 'SplitTimeState/getShowSplitTimes',
      showFinishSplit: 'SplitTimeState/getShowFinishSplit',
      showFinalLap: 'SplitTimeState/getShowFinalLap',
      showLeader: 'SplitTimeState/getShowLeader',
      bestTime: 'SplitTimeState/getBestTime',
      pb: 'SplitTimeState/getPb',
      wr: 'SplitTimeState/getWr',
      finalTimes: 'SplitTimeState/getFinalTimes'
    }),
    transform() {

      let scaleCoef = this.scaleCoef
      if (this.isWeb) scaleCoef *= 0.8
      return {
        transform: `scale(${scaleCoef})`
      }

    },
    isWeb() {

      return !this.isMobile()

    },
    wrFormated() {

      return timeManager.getTimeInFormatFromSeconds(this.wr)

    },
    players() {

      const players = []
      if (this.splitTimeData[0]) {

        players[0] = {
          name: this.splitTimeData[0].player.name,
          countryString: this.splitTimeData[0].countryString,
          country: this.splitTimeData[0].country,
          position: this.splitTimeData[0].position,
          time: this.splitTimeData[0].time.toFixed(2),
          timeColor: this.splitTimeData[0].player.isPlayer ? 'yellow' : '',
          finalTime: '',
          diff: this.splitTimeData[0].showDiff ? this.splitTimeData[0].timeDiff : '',
          showDiff: this.splitTimeData[0].showDiff,
          diffColor: this.splitTimeData[0].color,
          background: this.splitTimeData[0].player.isPlayer ? 'green' : ''
        }

      }
      if (this.splitTimeData[1]) {

        players[1] = {
          name: this.splitTimeData[1].player.name,
          countryString: this.splitTimeData[1].countryString,
          country: this.splitTimeData[1].country,
          position: this.splitTimeData[1].position === this.splitTimeData[0].position ?
            String(Number(this.splitTimeData[1].position) + 1) :
            this.splitTimeData[1].position,
          time: this.splitTimeData[1].time.toFixed(2),
          timeColor: this.splitTimeData[1].player.isPlayer ? 'yellow' : '',
          finalTime: '',
          diff: this.splitTimeData[1].showDiff ? this.splitTimeData[1].timeDiff : '',
          showDiff: this.splitTimeData[1].showDiff,
          diffColor: this.splitTimeData[1].color,
          background: this.splitTimeData[1].player.isPlayer ? 'green' : ''
        }

      }
      if (this.splitTimeData[2]) {

        const splitData = this.splitTimeData[2]
        const player0 = this.splitTimeData[0].player.isPlayer === splitData.player.isPlayer
        const index = player0 ? 0 : 1
        const timeData = this.showFinishSplit ?
          Math.round((splitData.time - this.splitTimeData[index].time) * 100) / 100 :
          this.splitTimeData[index].time

        players[2] = {
          name: splitData.player.name,
          countryString: splitData.countryString,
          country: splitData.country,
          position: splitData.position,
          time: timeData.toFixed(2),
          timeColor: splitData.player.isPlayer ? 'yellow' : '',
          finalTime: this.finalTimes[0] || '',
          diff: splitData.showDiff ? splitData.timeDiff : '',
          showDiff: splitData.showDiff,
          diffColor: splitData.color,
          background: splitData.player.isPlayer ? 'green' : ''
        }

      }
      if (this.splitTimeData[3]) {

        const splitData = this.splitTimeData[3]
        const player0 = this.splitTimeData[1].player.isPlayer === splitData.player.isPlayer
        const index = player0 ? 1 : 0
        const timeData = this.showFinishSplit ?
          Math.round((splitData.time - this.splitTimeData[index].time) * 100) / 100 :
          this.splitTimeData[index].time

        players[3] = {
          name: splitData.player.name,
          countryString: splitData.countryString,
          country: splitData.country,
          position: splitData.position === this.splitTimeData[2].position ?
            String(Number(splitData.position) + 1) :
            splitData.position,
          time: timeData.toFixed(2),
          timeColor: splitData.player.isPlayer ? 'yellow' : '',
          finalTime: this.finalTimes[1] || '',
          diff: splitData.showDiff ? splitData.timeDiff : '',
          showDiff: splitData.showDiff,
          diffColor: splitData.color,
          background: splitData.player.isPlayer ? 'green' : ''
        }

      }

      return players

    }

  }
})

</script>

<style lang="less">
.time-keeper-laps-container {
    position: absolute;
    left: 1%;
    top: 3%;
    display: flex;
    flex-direction: column;

    .lap-time-text {
        font-family: Roboto;
        font-size: 43px;
        font-weight: bold;
        font-style: italic;
        color: #fff;
    }

}
.finish-split {

    position: absolute;
    left: 1%;
    bottom: 3%;
    display: flex;
    flex-direction: column;

}
</style>
