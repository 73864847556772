import {
  THREE,
  type MaterialDataObject
} from '@powerplay/core-minigames'
import {
  MaterialsNames,
  TexturesNames
} from '../types'

/**
 * Konfig pre materialy
 */
export const materialsConfig: MaterialDataObject = {
  [MaterialsNames.skier]: {
    meshesArray: ['IceSkaterMale', 'IceSkaterFemale'],
    textureName: TexturesNames.skierClothes,
    playerIndex: 0
  },
  [MaterialsNames.skierOpponent]: {
    meshesArray: ['IceSkaterMaleOpponent', 'IceSkaterFemaleOpponent'],
    textureName: TexturesNames.skierClothesOpponent,
    playerIndex: 1
  },
  [MaterialsNames.hill]: {
    textureName: TexturesNames.hill,
    lightmap: TexturesNames.lightmapHill,
    vertexColors: true,
    isDefault: true
  },
  [MaterialsNames.ads]: {
    textureName: TexturesNames.ads,
    meshesArray: ['Ads', 'VictoryStands'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.flags]: {
    textureName: TexturesNames.flags,
    meshesArray: ['Flags'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.track]: {
    textureName: TexturesNames.iceTrack,
    meshesArray: ['Track', 'LaneMarkers'],
    vertexColors: true,
    lightmap: TexturesNames.lightmapHill,
    // envmap: TexturesNames.environmentReflection,
    reflectivity: 0.4
  },
  [MaterialsNames.emmisive]: {
    meshesArray: ['Emissive_Mesh', 'ScoreBoard_Screens'],
    textureName: TexturesNames.emissive
  },
  [MaterialsNames.staticPeople]: {
    textureName: TexturesNames.staticPeople,
    meshesArray: ['StaticPeople'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.audience]: {
    meshesArray: ['Audience'],
    alpha: 0.5,
    textureName: TexturesNames.audience,
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.ground]: {
    meshesArray: ['Ground'],
    textureName: TexturesNames.ground,
    vertexColors: true,
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.transparentAds]: {
    meshesArray: ['TransparentAds', 'TransparentTrackAds'],
    alpha: 0.5,
    textureName: TexturesNames.transparentAds
    // lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.lightBeams]: {
    meshesArray: ['LightBeams', 'LightFixtureBeams'],
    transparent: true,
    vertexColors: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false,
    textureName: TexturesNames.opacityGradient
  },
  [MaterialsNames.trackLinesFragments]: {
    meshesArray: ['TrackLinesFragments'],
    transparent: true,
    textureName: TexturesNames.trackLines
  }
}
