import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface BulletsTargetsBoxState {
  visible: boolean
  bullets: number
  targets: Array<boolean>
  showBlackScreen: boolean
}

const initialState = () => ({
  visible: false,
  bullets: 0,
  targets: [false, false, false, false, false],
  showBlackScreen: false
})

const bulletsTargetsBoxState: Module<BulletsTargetsBoxState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getDisplayBulletsTargets: (moduleState: BulletsTargetsBoxState) => moduleState.visible,
    getBullets: (moduleState: BulletsTargetsBoxState) => moduleState.bullets,
    getTargets: (moduleState: BulletsTargetsBoxState) => moduleState.targets,
    getShowBlack: (moduleState: BulletsTargetsBoxState) => moduleState.showBlackScreen
  },

  mutations: {
    RESET: (moduleState: BulletsTargetsBoxState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: BulletsTargetsBoxState, newState: BulletsTargetsBoxState) => {

      if (newState.visible !== undefined) moduleState.visible = newState.visible
      if (newState.bullets !== undefined) moduleState.bullets = newState.bullets
      if (newState.targets !== undefined) moduleState.targets = newState.targets
      if (newState.showBlackScreen !== undefined) {

        moduleState.showBlackScreen = newState.showBlackScreen

      }

    },
    SET_BULLETS: (moduleState: BulletsTargetsBoxState, bullets: number) => {

      moduleState.bullets = bullets

    },
    SET_TARGET_HIT: (moduleState: BulletsTargetsBoxState, hitTarget: number) => {

      moduleState.targets[hitTarget] = true

    },
    SET_IS_VISIBLE: (moduleState: BulletsTargetsBoxState, visible: boolean) => {

      moduleState.visible = visible

    },
    SET_BLACK_SCREEN: (moduleState: BulletsTargetsBoxState, show: boolean) => {

      moduleState.showBlackScreen = show

    }
  }
}

export default bulletsTargetsBoxState
