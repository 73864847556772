import {
  curvesConfig,
  gameConfig
} from '@/app/config'
import type { SpeedManager } from '@/app/SpeedManager/SpeedManager'
import {
  game,
  THREE
} from '@powerplay/core-minigames'
import type { TriggersManager } from '../triggers/TriggersManager'

/**
 * Metoda na vytvorenie ciary s ktorou sa neskor bude manipulovat na jazdenie
 */
export class HillLinesCreator {

  /** Object 3d na zmenu jazdy a smeru */
  private object3d = new THREE.Object3D()

  /** Percento kde je aktualne hrac */
  private actualPercent = gameConfig.startPercentOnCurve

  /** Percento kde bol naposledy hrac */
  private lastPercent = 0

  /** Percento kde bol v cieli hrac */
  private finishPercent = 0

  /** Kolko percent je 1 meter na krivke */
  public oneMeterInPercent = 0

  /** Offset pri idealnu liniu */
  public idealLineOffset = 0

  /** Posledny offset od idealu v zakrute */
  public lastOffsetFromIdealInTurn = 0

  /** trat hraca */
  private playerPaths: THREE.CurvePath<THREE.Vector3>[] = []

  /** aktivna cesta hraca */
  private activePlayerPathIndex = 0

  private finishCallback!: () => void

  /**
   * Konstruktor
   */
  public constructor(pathName: string, finishPathName: string) {

    const gameObjectWithLines = new THREE.Group()

    const trackPlayer = game.getObject3D(pathName)
    trackPlayer.visible = false
    this.playerPaths[0] = new THREE.CurvePath<THREE.Vector3>()
    this.setupCurvePathFromObject(trackPlayer as THREE.LineSegments, this.playerPaths[0])

    const trackPlayerFinish = game.getObject3D(finishPathName)
    trackPlayerFinish.visible = false
    this.playerPaths[1] = new THREE.CurvePath<THREE.Vector3>()
    this.setupCurvePathFromObject(trackPlayerFinish as THREE.LineSegments, this.playerPaths[1])

    gameObjectWithLines.add(trackPlayer)
    gameObjectWithLines.add(trackPlayerFinish)

    game.scene.add(gameObjectWithLines)

    this.calculateLineInfo()
    this.setupObject3d()
    // this.createDebugPoints()

  }

  /**
   * setter
   * @param callback - callback pri start triggeri
   */
  public setFinishCallback(callback: () => void): void {

    this.finishCallback = callback

  }

  /**
   * Vratenie aktualnych percent na trati
   * @returns hodnota % na trati
   */
  public getActualPercent(): number {

    return this.actualPercent

  }

  /**
   * Vratenie poslednych percent na trati
   * @returns hodnota % na trati
   */
  public getLastPercent(): number {

    return this.lastPercent

  }

  /**
   * Vratenie cielovych percent na trati
   * @returns hodnota % na trati
   */
  public getFinishPercent(): number {

    return this.finishPercent

  }

  /**
   * Update funkcia
   * @param speedManager - speedManager hraca
   * @param triggersManager - triggersManager hraca
   * @param opponentCheckpointsPassed - Pole absolvovanych medzicasov supera
   * @returns - Novy object 3D
   */
  public update(
    speedManager: SpeedManager,
    triggersManager: TriggersManager,
    opponentCheckpointsPassed: boolean[] = []
  ): THREE.Object3D {

    if (speedManager.isActive()) {

      // rychlost v m/frame
      const actualSpeed = speedManager.getActualSpeedPerFrame()

      // rychlost v %/frame
      const actualPercentSpeed = this.oneMeterInPercent * actualSpeed

      // musime si zapamatat posledne percento
      this.lastPercent = this.actualPercent

      // pridavame aktualnu rychlost v %/frame
      this.actualPercent += actualPercentSpeed

      if (this.actualPercent > 1) {

        if (this.activePlayerPathIndex >= 1) {

          // end
          this.object3d.position.setY(gameConfig.yCorrection)
          return this.object3d

        }

        this.finishPercent = this.actualPercent
        this.actualPercent = 0
        this.activePlayerPathIndex = 1
        this.calculateLineInfo()

        this.finishCallback()

      }

      triggersManager.checkActualTrigger(
        this.actualPercent,
        this.lastPercent,
        this.oneMeterInPercent,
        opponentCheckpointsPassed
      )

      this.setupObject3d()

    }

    this.object3d.position.setY(gameConfig.yCorrection)
    return this.object3d

  }

  /**
   * Vytvorenie curvePath z modelu
   * @param object - objekt s krivkou
   * @param curvePath - objekt, kde ulozime curvePath
   */
  private setupCurvePathFromObject(
    object: THREE.LineSegments,
    curvePath: THREE.CurvePath<THREE.Vector3>
  ): void {

    const pointsArray: number[] = Array.from(object.geometry.attributes.position.array)
    const coordinates: (THREE.Vector3 | undefined)[] = pointsArray
      .map((_: number, idx: number, origArray) => {

        if (idx % 3 !== 0) {

          return undefined

        }
        return new THREE.Vector3(
          origArray[idx],
          -origArray[idx + 2],
          origArray[idx + 1]
        )

      }).filter(e => e !== undefined)

    if (coordinates.includes(undefined)) {

      // error
      return

    }

    const lastPoint = new THREE.Vector3()
    coordinates.forEach((point, index) => {

      if (point === undefined) return
      if (index === 0) console.log(point)
      if (index > 0) {

        curvePath.add(new THREE.LineCurve3(
          new THREE.Vector3(lastPoint.x, lastPoint.y, lastPoint.z),
          new THREE.Vector3(point.x, point.y, point.z)
        ))

      }

      lastPoint.copy(point)

    })

  }

  /**
   * Vyratanie dlzky ciary a jedneho metra na ciare
   */
  private calculateLineInfo(): void {

    const totalPlayerPathLength = this.playerPaths[this.activePlayerPathIndex].getLength()

    // takisto este potrebujeme 1m kolko je %
    this.oneMeterInPercent = 1 / totalPlayerPathLength
    console.log(this.oneMeterInPercent)

  }

  /**
   * nastavenie object3d na zaciatku
   */
  private setupObject3d(): void {

    const point = this.playerPaths[this.activePlayerPathIndex]
      .getPointAt(this.actualPercent)
    const pointToLookAt = this.playerPaths[this.activePlayerPathIndex]
      .getPointAt(this.actualPercent + 0.0001)

    if (point) {

      this.object3d.position.set(point.x, point.y, point.z)

    }
    if (pointToLookAt) this.object3d.lookAt(pointToLookAt)

  }

  /** vytvorenie a zobrazenie znaciek pre debug */
  public createDebugPoints(): void {

    curvesConfig.curvesPlayer.start.forEach((point) => {

      const vector = this.playerPaths[0].getPointAt(point)

      const geometry = new THREE.BoxGeometry(1, 1, 1)
      const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 })
      const debugMarker = new THREE.Mesh(geometry, material)
      debugMarker.position.set(vector.x, vector.y, vector.z)
      game.scene.add(debugMarker)

    })
    curvesConfig.curvesPlayer.end.forEach((point) => {

      const vector = this.playerPaths[0].getPointAt(point)

      const geometry = new THREE.BoxGeometry(1, 1, 1)
      const material = new THREE.MeshBasicMaterial({ color: 0x00fff0 })
      const debugMarker = new THREE.Mesh(geometry, material)
      debugMarker.position.set(vector.x, vector.y, vector.z)
      game.scene.add(debugMarker)

    })

  }

  /** reset */
  public reset(): void {

    this.actualPercent = gameConfig.startPercentOnCurve
    this.activePlayerPathIndex = 0
    this.calculateLineInfo()
    this.setupObject3d()

  }

}
