import { ImpulseQualityTexts } from '../types'

/**
 * Konfig pre kameru pre training
 */
export const trainingConfig = {

  /** Kvalita pre rytmus podla hlasky */
  impulsesQualities: {
    [ImpulseQualityTexts.poor]: 0,
    [ImpulseQualityTexts.good]: 50,
    [ImpulseQualityTexts.excellent]: 80,
    [ImpulseQualityTexts.perfect]: 100,
  },

  /** Kvalita startu podla GD - hodnoty su po frameoch */
  startQualities: [
    0,
    0, // 1
    0,
    100,
    100,
    100, // 5
    95,
    90,
    85,
    80,
    70, // 10
    60,
    50,
    25,
    15,
    5 // 15
  ]

}

/*
 * 3-5 framov - 100%
 * 6 framov - 95%
 * 7 framov - 90%
 * 8 framov - 85%
 * 9 framov - 80%
 * 10 framov - 70%
 * 11 framov - 60%
 * 12 framov - 50%
 * 13 framov - 25%
 * 14 framov - 15%
 * 15+ framov - 5%
 */
