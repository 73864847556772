import {
  gsap,
  CameraStates,
  corePhasesManager,
  modes,
  timeManager,
  TimesTypes,
  cameraManager,
  audioManager
} from '@powerplay/core-minigames'
import {
  type DisciplinePhaseManager,
  type DisplayMessage,
  StartQuality,
  Tasks,
  TutorialEventType,
  TutorialObjectiveIds,
  AudioNames
} from '../../types'

import store from '@/store'
import { player } from '../../entities/player'
import { inputsManager } from '../../InputsManager'
import {
  gameConfig,
  startPhaseConfig,
  trainingConfig,
  velocityConfig
} from '../../config'
import { startPhaseStateManager } from './StartPhaseStateManager'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import { opponent } from '@/app/entities/Opponent/Opponent'
import { trainingTasks } from '@/app/modes/training'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { audioHelper } from '@/app/audioHelper/AudioHelper'

/**
 * Trieda pre startovaciu fazu
 */
export class StartPhaseManager implements DisciplinePhaseManager {

  /** pocet framov od vystrelu po start */
  public framesFromShot = 0

  /** ci sa deje nieco skipnutelne */
  public skippable = true

  /** ci uz je mozne odstartovat */
  private startable = false

  /** ci uz zobrazit ui player-info-avatar */
  private showName = false

  /** ci bolo skipnute */
  private skipped = false

  /** ci faza skoncila */
  public ended = false

  /** Ci bol dokonaly start */
  public perfectOrExcellentStart = false

  /** Specialne zobrazenie baru v tutoriali */
  private showBarInTutorial = false

  /** hodnota na ktorej stlacil */
  public clickedPower = 0

  /** Pocet frameov od zaciatku fazy */
  private framesCounter = 0

  /** tween na skrytie odrazovej hlasky */
  public startingMessageTween !: gsap.core.Tween

  /** tween na zacatie disciplinoveho intra */
  private launchSystemTween!: gsap.core.Tween

  /** tween na spustenie pipania */
  private startRunSoundTween!: gsap.core.Tween

  /** timeline na spustenie pipania */
  private startRunSoundTimeline!: gsap.core.Timeline

  /** tween pipania */
  private soundCounterTween!: gsap.core.Timeline

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** ako dlho bude zobrazena hlaska na odraze */
  private STARTING_MESSAGE_DURATION = 3

  /** Premenna pre kameru */
  private cameraInPostIntroState = false

  /** kvalita startu */
  private startQuality: StartQuality | undefined

  /** tween na odpocitavanie do restartu */
  private failedStartTween!: gsap.core.Timeline | undefined

  /** povolenie skipnutia po zlom starte */
  private skippableFailedStart = false

  /** zobrazenie bielej ziary */
  private showShine = false

  /** Konstruktor */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase(): void {

    this.storeState()
    startPhaseStateManager.disableInputs()

  }

  /**
   * Zacatie fazy
   */
  public startPhase(): void {

    // musime nastavit aj uuid a ked ide o hraca, kedze reset ich vymaze
    opponent.setOpponentUuid()
    player.triggersManager.splitTimeManager.isInstanceOfPlayable = true

    /*
     * splitCount musime nastavovat tu, lebo pri resete (false start napr) sa vytvara novy
     * objekt a tym padom ostane hodnota na default 0 a nefunguju veci
     */
    player.triggersManager.splitTimeManager.setSplitCount()
    opponent.triggersManager.splitTimeManager.setSplitCount()

    if (this.ended) {

      this.reset()

      this.afterCameraDisciplineIntro()
      this.removeBlackOverlay()

    } else {

      console.warn('starting start phase')
      // Zobrazit meno hraca v UI
      this.showName = !modes.isTutorial()
      this.setCameraForDisciplineIntro()
      console.log(cameraManager)

    }

  }

  /**
   * nastavi hraca na zaciatok
   */
  public setStartingPositionAndRotation(): void {

    player.playerObject.position.x = gameConfig.startPosition.x
    player.playerObject.position.y = gameConfig.startPosition.y
    player.playerObject.position.z = gameConfig.startPosition.z

  }

  /**
   * Nastavenie kamery pre intro
   */
  private setCameraForDisciplineIntro(): void {

    if (gameConfig.cameraConfig.enabled) {

      player.setGameCameraSettings()

    }
    if (modes.isTutorial()) {

      this.afterCameraDisciplineIntro()
      tutorialFlow.init()
      tutorialUIChange.init()
      return

    }

    cameraManager.setState(CameraStates.disciplineIntro)
    cameraManager.playTween(false, this.afterCameraDisciplineIntro)

  }

  /** pomocna metoda pre animacie */
  public getCameraInPostIntroState(): boolean {

    return this.cameraInPostIntroState

  }

  /**
   * Spravenie veci po konci disciplinoveho intra
   */
  private afterCameraDisciplineIntro = (): void => {

    audioManager.stopAudioByName(AudioNames.audienceNoise)
    audioManager.stopAudioByName(AudioNames.audienceSad)

    this.cameraInPostIntroState = true
    opponent.preStart()
    cameraManager.setState(CameraStates.discipline)

    startPhaseStateManager.postIntroUiState()

    if (modes.isTutorial()) return
    startPhaseStateManager.showStartMessageInUI({ message: 'READY',
      color: 1 })
    console.log('READY')
    audioManager.play(AudioNames.ready)

  }

  /**
   * Zobrazenie start baru v tutoriali
   */
  public showBarTutorial = (): void => {

    this.showBarInTutorial = true
    store.commit('TutorialState/SET_SHOW_BAR_START', true)
    this.storeState()

  }

  /**
   * Spustenie pipania
   */
  public launchSystem = (): void => {

    this.skippable = false

    cameraManager.setState(CameraStates.discipline)

    const duration = 0.5// this.skipped ? 1 : 0

    // Zrusit zobrazenie mena hraca v UI
    this.showName = false

    // davam maly delay kvoli tomu, ze predtym mohol byt skip
    this.startRunSoundTween = gsap.to({}, {
      onComplete: () => {

        this.framesCounter = 0
        this.startable = true
        startPhaseStateManager.showInputs()
        startPhaseStateManager.enableStartInputs()

      },
      duration
    })

    if (!modes.isTutorial()) return
    startPhaseStateManager.showStartMessageInUI({ message: 'READY',
      color: 1 })
    console.log('READY in launch system')

  }

  /**
   * Update kazdy frame
   */
  public update(): void {

    this.framesCounter++
    this.autoStart()

    if (this.startQuality === StartQuality.failed) {

      player.speedManager.manageFailedStart()
      opponent.speedManager.manageFailedStart()

    }

    const dirLeft = inputsManager.moveDirectionLeft ||
        store.getters['MovementState/getPositionX'] < 0

    const dirRight = inputsManager.moveDirectionRight ||
        store.getters['MovementState/getPositionX'] > 0


    if ((dirLeft || dirRight) && this.startable) {

      this.startClicked()

    }


    if (
      inputsManager.actionPressed &&
            this.skippable &&
            this.framesCounter > 6
    ) {

      console.log('skippped')
      this.skippable = false
      this.skipped = true
      cameraManager.skipTween()
      this.framesCounter = 0

    }

    if (
      inputsManager.actionPressed &&
            this.skippableFailedStart
    ) {

      this.skipAfterFailed()

    }

    if (this.startable && this.framesCounter === startPhaseConfig.startAfterFrames) {

      timeManager.setActive(TimesTypes.game, true)
      store.commit('UiState/SET_TIME_VISIBILITY', true)

      startPhaseStateManager.showStartMessageInUI({ message: 'Start',
        color: 0 })
      opponent.start()
      console.log('START SHOT')
      this.showShine = true
      audioHelper.playFirstShotAudio()

      gsap.to({}, {
        onComplete: () => {

          this.showShine = false,

          store.commit('StartPhaseState/SET_SHOW_SHINE', this.showShine)

        },
        duration: 0.2
      })

    }

    this.storeState()

  }

  /**
   * skip po failed starte
   */
  private skipAfterFailed(): void {

    console.log('skipping')
    this.skippableFailedStart = false
    this.startingMessageTween?.kill()
    this.failedStartTween?.kill()
    this.createBlackOverlay()
    gsap.to({}, {
      onComplete: () => {

        this.skippableFailedStart = false
        console.warn('restart')
        disciplinePhasesManager.resetAttempt()

      },
      duration: 0.5
    })

  }

  /**
   * Create black overlay
   */
  public createBlackOverlay(): void {

    if (disciplinePhasesManager.attempt >= 2) return
    store.commit('BlackOverlay/SET_OVERLAY', true)

  }

  /**
   * Remove black overlay
   */
  public removeBlackOverlay(): void {

    store.commit('BlackOverlay/SET_OVERLAY', false)

  }

  /**
   * po kliknuti na starte
   */
  private startClicked(): void {

    if (gameConfig.autoMove.isEnabled) return

    this.startable = false

    this.resolveStartQuality()

  }

  /**
   * auto start
   */
  private autoStart(): void {

    if (!gameConfig.autoMove.isEnabled || !this.startable) return

    const startFrames = startPhaseConfig.startAfterFrames + gameConfig.autoMove.startFrames

    if (this.framesCounter === startFrames) this.resolveStartQuality()



  }

  /**
   * vyhodnotenie startu
   */
  private resolveStartQuality(): void {

    this.trainingStartQuality()

    const { startAfterFrames, startQualityFrames } = startPhaseConfig

    this.framesFromShot = this.framesCounter - startAfterFrames
    console.log(`Hracov reakcny cas (frame) = ${this.framesFromShot}`)

    let audio = AudioNames.commentAfterStart4

    if (this.framesCounter < startAfterFrames + startQualityFrames.perfect) {

      this.failedStart()
      return

    } else if (this.framesCounter < startAfterFrames + startQualityFrames.excelent) {

      audio = AudioNames.commentAfterStart1
      console.log('perfect start')
      this.startQuality = StartQuality.perfect
      this.perfectOrExcellentStart = true

    } else if (this.framesCounter < startAfterFrames + startQualityFrames.good) {

      audio = AudioNames.commentAfterStart2
      console.log('excelent start')
      this.startQuality = StartQuality.excelent
      this.perfectOrExcellentStart = true

    } else if (this.framesCounter < startAfterFrames + startQualityFrames.poor) {

      audio = AudioNames.commentAfterStart3
      console.log('good start')
      this.startQuality = StartQuality.good

    } else {

      console.log('poor start')
      this.startQuality = StartQuality.poor

    }

    tutorialObjectives.passObjective(TutorialObjectiveIds.start as string)
    tutorialFlow.eventActionTrigger(TutorialEventType.goodStart)

    store.commit('SpeedmeterState/SET_VISIBILITY', velocityConfig.showSpeed)
    player.speedManager.setActive(true)
    this.finishPhase()
    this.showStartMessage()

    if (modes.isTutorial()) return

    audioManager.play(audio)

  }

  /**
   * Ziskanie kvality startu v treningu
   */
  private trainingStartQuality() {

    if (!modes.isTrainingMode()) return
    const { startAfterFrames } = startPhaseConfig
    const { startQualities } = trainingConfig

    let delta = this.framesCounter - startAfterFrames
    // Na prvy pokus ak sa pokazi sa to ignoruje
    if (disciplinePhasesManager.attempt === 1 && delta < 0) return
    if (delta < 1) delta = 0
    if (delta > 15) delta = 15
    const value = startQualities[delta] / 100
    trainingTasks.saveTaskValue(Tasks.startQuality, value)

  }

  /**
   * failed start
   */
  private failedStart(): void {

    console.log('failed start')
    player.speedManager.setActive(true)
    opponent.failedStart()
    this.ended = true
    this.startQuality = StartQuality.failed

    player.speedManager.failedStartAcceleration = true
    opponent.speedManager.failedStartAcceleration = true

    player.launchStartAnimation(true)
    audioHelper.playFalseStartAudio()

    this.failedStartTween = gsap.timeline()

    this.failedStartTween.to({}, {
      onComplete: () => {

        this.showStartMessage()
        player.speedManager.failedStartAcceleration = false
        opponent.speedManager.failedStartAcceleration = false

      },
      duration: 1
    })
    this.failedStartTween.to({}, {

      onComplete: () => {

        this.skippableFailedStart = !modes.isTutorial()

      },
      duration: 0.5
    })
    this.failedStartTween.to({}, {
      onComplete: () => {

        tutorialFlow.eventActionTrigger(TutorialEventType.failedStart)

      },
      duration: 1.5
    })
    this.failedStartTween.to({}, {
      onComplete: () => {

        this.createBlackOverlay()

      },
      duration: 1.5
    })
    this.failedStartTween.to({}, {
      onComplete: () => {

        this.skippableFailedStart = false
        console.warn('restart')
        disciplinePhasesManager.resetAttempt()

      },
      duration: 0.5
    })

    if (modes.isTutorial()) tutorialFlow.failedStartCount += 1

  }

  /**
   * kill all tweens
   */
  private killAllTweens(): void {

    if (this.launchSystemTween) this.launchSystemTween.kill()
    if (this.startRunSoundTween) this.startRunSoundTween.kill()
    if (this.soundCounterTween) this.soundCounterTween.kill()
    if (this.startRunSoundTimeline) this.startRunSoundTimeline.kill()

  }

  /**
   * Ukoncene fazy
   */
  public finishPhase(): void {

    if (!this.ended) {

      console.warn('start phase ended')

      this.ended = true
      this.killAllTweens()
      this.storeState()

      startPhaseStateManager.finishPhaseUiState()
      startPhaseStateManager.disableInputs()
      this.callbackEnd()

    }

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * UI update
   */
  private storeState(): void {

    store.commit('StartPhaseState/SET_STATE', {
      clickedPower: this.clickedPower,
      showName: this.showName,
      showPhase: !this.ended,
      startable: this.startable,
      showBar: (this.startable || this.showBarInTutorial) && !this.ended,
      attempt: corePhasesManager.disciplineActualAttempt,
      showShine: this.showShine
    })

  }

  /**
   * zobrazime startovu spravu
   */
  private showStartMessage(): void {

    const message = this.getStartingMessage()
    startPhaseStateManager.showStartMessageInUI(message)

    this.startingMessageTween = gsap.to({}, {
      duration: this.STARTING_MESSAGE_DURATION,
      onComplete: () => {

        startPhaseStateManager.disableStartMessageInUI(message)

      }
    })

  }

  /**
   * ziskame startovu spravu
   */
  private getStartingMessage(): DisplayMessage {

    const message = { message: 'slowStart',
      color: 2 }

    if (this.startQuality === StartQuality.perfect) {

      message.message = 'perfectStart'
      message.color = 0

    } else if (this.startQuality === StartQuality.excelent) {

      message.message = 'excellentStart'
      message.color = 0

    } else if (this.startQuality === StartQuality.good) {

      message.message = 'goodStart'
      message.color = 1

    } else if (this.startQuality === StartQuality.failed) {

      message.message = 'utFalseStart'
      message.color = 2

    }

    return message

  }

  /**
   * reset fazy
   */
  private reset(): void {

    store.commit('InputsState/SET_DISABLED', true)
    player.speedManager.reset()
    this.startable = false
    this.showName = false
    this.skipped = false
    this.ended = false
    this.clickedPower = 0
    this.framesCounter = 0
    this.startQuality = undefined
    this.perfectOrExcellentStart = false
    // this.setStartingPositionAndRotation()
    this.storeState()

  }

}
