import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface StartMessageState {
  showMessage: boolean,
  messageText: string,
  messageColor: number
}

const initialState = () => ({
  showMessage: false,
  messageText: '',
  messageColor: 0
})

const startMessageState: Module<StartMessageState, RootState> = {
  namespaced: true,
  state: initialState(),

  getters: {
    getStartMessageState: (moduleState: StartMessageState) => moduleState
  },

  mutations: {
    RESET: (moduleState: StartMessageState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: StartMessageState, newState: StartMessageState) => {

      moduleState.showMessage = newState.showMessage
      moduleState.messageText = newState.messageText
      moduleState.messageColor = newState.messageColor

    }
  }
}

export default startMessageState
