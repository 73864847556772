import store from '@/store'
import {
  corePhasesManager,
  minigameConfig,
  modes,
  playersManager,
  requestManager,
  type TrainingDataFromResultsRequest,
  trainingManager,
  gameStats
} from '@powerplay/core-minigames'
import { opponent } from './entities/Opponent/Opponent'
import { player } from './entities/player'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { tutorialObjectives } from './modes/tutorial/TutorialObjectives'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import type { RunningPhase } from './phases/RunningPhase/RunningPhase'
import type { RunUpPhase } from './phases/RunUpPhase/RunUpPhase'
import type { StartPhaseManager } from './phases/StartPhase/StartPhase'
import {
  DisciplinePhases,
  type SaveResultsDataToSend,
  TutorialObjectiveIds
} from './types'

/**
 * Trieda pre koniec discipliny
 */
export class EndManager {

  /** ci uz bol result poslany alebo nie */
  private resultSent = false

  /**
   * Poslanie requestu pre konecne logovanie
   */
  public sendLogEnd(): void {

    // ak uz mame nastavene, tak uz viac nenastavujeme
    if (Object.keys(gameStats.getDisciplineDataToLog()).length > 0) return

    const startPhaseManager = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
    const runUpPhaseManager = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.runUp) as RunUpPhase
    const runningPhaseManager = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase

    const sendTimeZero = playersManager.dnf || modes.isTutorial() || modes.isTrainingMode()

    const time = playersManager.players[0].resultsArr?.[
      corePhasesManager.disciplineActualAttempt - 1
    ].main || 0
    // zaznamename nejake info pre logy
    gameStats.setDisciplineDataToLog({
      time: sendTimeZero ? 0 : time,
      dnf: playersManager.dnf,
      dsq: disciplinePhasesManager.wasDsq,
      attempts: disciplinePhasesManager.attempt,
      split: playersManager.dnf ?
        [] :
        player.triggersManager.splitTimeManager.getAllSplitTimes(),
      framesFromShot: startPhaseManager.framesFromShot,
      qualities: {
        runUp: runUpPhaseManager.runUpQuality,
        skating: runningPhaseManager.impulseInputManager.getAverageQuality(),
        skatingStraight: runningPhaseManager.impulseInputManager.getStraightAvgQuality(),
        skatingCurve: runningPhaseManager.impulseInputManager.getCurveAvgQuality()
      },
      playerPosition: playersManager.getPlayerActualPosition(),
      trainingTasks: modes.isTrainingMode() ?
        trainingManager.getTrainingTasks().map(task => task.value) :
        [],
      tutorialData: modes.isTutorial() ? this.getTutorialLogs() : []
    })

    console.log('LOG to send', gameStats.getDisciplineDataToLog())

  }

  /**
   * ziskame pole tutorialovych logov
   *
   * @returns - pole tutorialovych logov
   */
  private getTutorialLogs(): (number | boolean)[] {

    /*
     * Kolkokrat hrac dal false start
     * Ci hrac splnil ulohu akceleracia
     * Ci hrac splnil ulohu pocet excellent/perfect zaberov
     * Pocet tutorialov kde hrac splnil vsetky ulohy
     * Počet predcasne ukoncenych tutorialov
     */

    return [
      tutorialFlow.failedStartCount, // falseStartCount
      tutorialObjectives
        .checkIfObjectivePassed(tutorialObjectives.getObjectiveById(TutorialObjectiveIds.acceleration)),
      // wasAccelerationSuccess
      tutorialObjectives
        .checkIfObjectivePassed(tutorialObjectives.getObjectiveById(TutorialObjectiveIds.impulses)),
      // wasImpulsesSuccess
      tutorialObjectives.isAllObjectivesPassed(), // completed
      disciplinePhasesManager.prematureEnded // exited
    ]

  }

  /**
   * Vybratie dat a poslanie do funkcie z core-minigames
   */
  public sendSaveResult(): void {

    // ak uz bol result poslany, neposielame ho znova
    if (this.resultSent) return

    this.resultSent = true

    // TODO TEMP - zatial takto, ked bude hotovy tutorial, tak sa to bude posielat tam
    requestManager.sendTutorialRequest()
    if (modes.isTutorial()) return

    const startPhaseManager = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
    const runUpPhaseManager = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.runUp) as RunUpPhase
    const runningPhaseManager = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase

    const time = playersManager.players[0].resultsArr?.[
      corePhasesManager.disciplineActualAttempt - 1
    ].main || 0

    const data: SaveResultsDataToSend = {
      time: time,
      positions: playersManager.getPlayersPositions(),
      dnf: playersManager.dnf,
      dsq: disciplinePhasesManager.wasDsq,
      split_times: player.triggersManager.splitTimeManager.getAllSplitTimes(),
      perfectOrExcellentStart: startPhaseManager.perfectOrExcellentStart,
      perfectRunUp: runUpPhaseManager.perfectRunUp,
      perfectImpulses: runningPhaseManager.impulseInputManager.perfectImpulses,
      perfectImpulsesCurve: runningPhaseManager.impulseInputManager.perfectImpulsesCurves
    }

    if (opponent.finished) {

      data.opponentID = opponent.uuid
      data.opponentTime = playersManager.getPlayerById(opponent.uuid)?.resultsArr?.[0].main ||
        minigameConfig.dnfValue
      data.opponentSplit = opponent.triggersManager.splitTimeManager.getAllSplitTimes()

    }

    if (modes.isTrainingMode()) {

      data.trainingResults = trainingManager.getTrainingTasks().map(task => task.value)

    }

    console.log('data to send', data)

    requestManager.sendSaveResultsRequest(
      (dataCallback: TrainingDataFromResultsRequest | unknown) => {

        console.log('saveRequest Successful')

        if (modes.isTrainingMode()) {

          store.commit('TrainingResultsState/SET_STATE_DATA', {
            data: dataCallback,
            bestScore: trainingManager.bestScore
          })

        }

      },
      JSON.stringify(data)
    )

  }

  /**
   * Reset result
   */
  public reset(): void {

    this.resultSent = false

  }

}

export const endManager = new EndManager()
