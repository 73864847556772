/**
 * konfig running fazy
 */
export const runUpPhaseConfig = {

  /** dlzka rozbehu v sekundach */
  runUpDuration: 5,

  /*
   * 99-100% - Maximálna rýchlosť
   * 90-98% - Výborná rýchlosť
   * 75-89% - Dobrá rýchlosť
   * 0-75% - Slabá rýchlosť
   */
  runUpQuality: {
    perfect: 99,
    excelent: 90,
    good: 75,
    poor: 0
  }

}
