import { PlayerAnimationsNames } from './config'
import { THREE } from '@powerplay/core-minigames'

/** Strany */
export enum Sides {
  LEFT = 'Left',
  RIGHT = 'Right'
}

/** Specialny vektor na osiach X a Z */
export interface VectorXZ {
  x: number,
  z: number
}

/** Typ vah pohybovych animacii */
export interface PlayerMovementAnimationsWeightType {

  animation: PlayerAnimationsNames,
  weight: number,
  value: number,

}

/** Typy animacnych vah */
export enum PlayerMovementAnimationsWeightTypes {
  crouchCenter = 'crouchCenter',
  crouchLeft = 'crouchLeft',
  crouchRight = 'crouchRight',
  standCenter = 'standCenter',
  standLeft = 'standLeft',
  standRight = 'standRight',
}

/** Setup vah pohybovych animacii */
export type PlayerMovementAnimationsWeightsSetup = {
  [key in PlayerMovementAnimationsWeightTypes]: PlayerMovementAnimationsWeightType
};

/*
 * export type PositionTupleValue = 1 | -1
 * export type PosX = PositionTupleValue
 * export type PosY = PositionTupleValue
 * export type PositionTuple = [PosX, PosY]
 */
export type PositionTuple = [number, number]

/** Info ohladom priesecnika hraca a objektu */
export interface IntersectionInfo {

  normal: THREE.Vector3,
  distance?: number,
  point: THREE.Vector3

}

export enum ImpulseQualityTexts {
  poor = 'poor',
  good = 'good',
  excellent = 'excellent',
  perfect = 'perfect'
}

/** Typy hracov */
export enum PlayerTypes {

  player = 'Player',
  opponent = 'Opponent'

}
