/**
 * konfig running fazy
 */
export const runningPhaseConfig = {

  /** Pauza medzi naplnanim laveho a praveho baru */
  changeFreezeDuration: 2,

  /** pocet framov, ako dlho po rozbehu este nebude impulse input */
  startImpulseDelay: 30,

  /** koeficienty pridavania rychlosti podla baru */
  idealSpeedIncreaseLow: 0.4,
  idealSpeedIncreaseMedium: 1,
  idealSpeedIncreaseHigh: 2.5,

  manualCrossfadeAnimationSpeed: 0.1

}
