
/** Nazvy zvukov */
export enum AudioNames {

  spriteCommentators = 'commentators',
  spriteLooped = 'looped',
  spriteNotLooped = 'notLooped',
  spriteMovementRightOpponent = 'movementRightOpponent',
  spriteMovementLeftOpponent = 'movementLeftOpponent',
  spriteMovementRightPlayer = 'movementRightPlayer',
  spriteMovementLeftPlayer = 'movementLeftPlayer',

  ready = 'vo_ready',
  startShot = 'start_shot',
  falseStartShot = 'false_start_shots',
  lastRoundBell = 'last_round_bell_v2',

  audienceHype = 'audience_hype',
  audienceNoise = 'audience_noise_var_02',
  audienceSad = 'audience_sad',
  audienceYay = 'audience_yay2',

  skateRight = 'ice_skating_right_player',
  skateLeft = 'ice_skating_left_player',
  skateCurveRight = 'ice_skating_curve_right_player',
  skateCurveLeft = 'ice_skating_curve_left_player',
  skateStartLeft = 'ice_skating_runup_left_player',
  skateStartRight = 'ice_skating_runup_right_player',

  skateRightOpponent = 'ice_skating_right_opponent',
  skateLeftOpponent = 'ice_skating_left_opponent',
  skateCurveRightOpponent = 'ice_skating_curve_right_opponent',
  skateCurveLeftOpponent = 'ice_skating_curve_left_opponent',
  skateStartLeftOpponent = 'ice_skating_runup_left_opponent',
  skateStartRightOpponent = 'ice_skating_runup_right_opponent',

  commentFinalResults = 'final_results',
  commentFinalResults2 = 'final_results2',
  commentBeforeFinish = 'before_finish',
  commentBeforeFinish2 = 'before_finish2',
  commentFinish1 = 'finish_1',
  commentFinish2 = 'finish_2',
  commentFinish3 = 'finish_3',
  commentFinish4 = 'finish_4',
  commentAfterStart1 = 'VO_after_start_01',
  commentAfterStart2 = 'VO_after_start_02',
  commentAfterStart3 = 'VO_after_start_03',
  commentAfterStart4 = 'VO_after_start_04',
  commentSplitTimesLead = 'VO_split_time_lead',
  commentSplitTimesLead2 = 'VO_split_time_lead2',
  commentSplitTimesClose = 'VO_split_time_close',
  commentSplitTimesClose2 = 'VO_split_time_close2',
  commentSplitTimesBad = 'VO_split_time_bad',
  commentSplitTimesBad2 = 'VO_split_time_bad2',
  commentLaneSwitch = 'VO_lane_switch',
  commentLaneSwitch2 = 'VO_lane_switch2',
  commentIntro = 'VO_intro_0',
  commentIntro2 = 'VO_intro_02',
}

/** Kategorie zvukov */
export enum AudioCategories {

  startend = 'startend',
  audience = 'audience',
  movement = 'movement',
  commentators = 'commentators',
  sprite = 'sprites'

}

/** skupiny zvukov */
export enum AudioGroups {

  commentators = 'commentators',
  movementOpponent = 'movementOpponent',
  movementPlayer = 'movementPlayer'

}
