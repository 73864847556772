<template>
  <game-speed-skating-component />
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import GameSpeedSkatingComponent from './components/GameSpeedSkatingComponent.vue'

export default defineComponent({
  components: {
    GameSpeedSkatingComponent
  },
})

</script>
