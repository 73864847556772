export const opponentConfig = {

  /** pocty framov medzi ktorymi je random delay startu opponenta */
  startFrames: {

    from: 6,
    to: 9

  },

  /** speedbar conf */
  speedBar: {

    /** ako casto pri rozbehu aktivuje opponent input */
    opponentSpeedBarInputRateFixed: 3,
    opponentSpeedBarRandomFrequency: 3,
    opponentSpeedBarInputRateMin: 4,
    opponentSpeedBarInputRateMax: 5

  },

  /** pocty % medzi akymi random prida silu opponentovi pri behu */
  runningImpulseCoef: {

    from: 0.84,
    to: 1

  },

  /** delay v sekundach, aby nemali rovnaku animaciu prepare */
  prepareDelay: 0.6
}
