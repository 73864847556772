/**
 * Config pre start fazu
 */
export const startPhaseConfig = {

  /** Po kolkych framoch sa povoli start */
  startAfterFrames: 90,

  /**
   * framy pre kvalitu startu
   * 3-4 framy - Dokonalý štart
   * 5-6 framov - Výborný štart
   * 7-8 framov - Dobrý štart
   * 9+ framov - Pomalý štart
   */
  startQualityFrames: {
    perfect: 3,
    excelent: 6,
    good: 9,
    poor: 11
  },

  /** Rychlost animacie start */
  animationSpeed: 1.87

}
