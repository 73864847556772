import { Sides } from '@/app/types'
import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface RunUpBarState {
  showBar: boolean,
  speedBarValue: number,
  maxSpeed: number,
  nextImpulse: Sides | undefined
}

const initialState = () => ({
  showBar: false,
  speedBarValue: 0,
  maxSpeed: 100,
  nextImpulse: undefined
})

const runUpBarState: Module<RunUpBarState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getRunUpBarState: (moduleState: RunUpBarState) => moduleState,
    getSpeedBarValue: (moduleState: RunUpBarState) => moduleState.speedBarValue,
    getMaxSpeed: (moduleState: RunUpBarState) => moduleState.maxSpeed,
    getNextImpulse: (moduleState: RunUpBarState) => moduleState.nextImpulse,
    getShowBar: (moduleState: RunUpBarState) => moduleState.showBar
  },

  mutations: {
    RESET: (moduleState: RunUpBarState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: RunUpBarState, newState: RunUpBarState) => {

      if (moduleState.showBar !== undefined) moduleState.showBar = newState.showBar
      if (moduleState.speedBarValue !== undefined) {

        moduleState.speedBarValue = newState.speedBarValue

      }

    },
    SET_MAX_SPEED: (moduleState: RunUpBarState, speed: number) => {

      moduleState.maxSpeed = speed

    },
    SET_SHOW: (moduleState: RunUpBarState, isShown: boolean) => {

      moduleState.showBar = isShown

    },
    SET_SPEED_BAR: (moduleState: RunUpBarState, speedBarValue: number) => {

      moduleState.speedBarValue = speedBarValue

    },
    SET_NEXT_IMPULSE: (moduleState: RunUpBarState, nextImpulse: Sides | undefined) => {

      moduleState.nextImpulse = nextImpulse

    }

  }
}

export default runUpBarState
