
/** Stavy protihraca */
export enum OpponentState {
  prepare = 'prepare',
  start = 'start',
  runUp = 'runUp',
  flat = 'flat',
  curve = 'curve',
  finish = 'finish',
  failedStart = 'failedStart'
}
