import type {
  LoaderDataTypes,
  MaterialDataObject
} from '@powerplay/core-minigames'

/** Mena vsetkych modelov, ktore sa pouzivaju v minihre */
export enum ModelsNames {
  skier = 'skier',
  opponent = 'opponent',
  hill = 'hill',
  trackLines = 'TrackLines'
}

/** Mena vsetkych textur, ktore sa pouzivaju v minihre */
export enum TexturesNames {
  skierRacePrefix = 'skier_race_',
  skierRaceWhiteMan = 'male/skier_race_white',
  skierRaceMulattoMan = 'male/skier_race_mulatto',
  skierRaceBlackMan = 'male/skier_race_black',
  skierRaceWhiteWoman = 'female/skier_race_white',
  skierRaceMulattoWoman = 'female/skier_race_mulatto',
  skierRaceBlackWoman = 'female/skier_race_black',
  skierClothes = 'skier_clothes',
  skierClothesOpponent = 'skier_clothes_opponent',
  hill = 'Atlas',
  // transparent = 'TransparentTexture',
  ads = 'Ads',
  flags = 'flags',
  iceTrack = 'IceTrack',
  // people = 'People',
  lightmapHill = 'LightmapHill',
  staticPeople = 'StaticPeople',
  audience = 'AudienceTexture',
  ground = 'GroundTexture',
  environmentReflection = 'EnvironmentReflection',
  /*
   * impact = 'impact',
   * startAds = 'logoTransparent',
   */
  transparentAds = 'TransparentAds',
  opacityGradient = 'OpacityGradient',
  emissive = 'Emissive',
  trackLines = 'TrackLines'
}

/** Mena vsetkych materialov, ktore sa pouzivaju v minihre */
export enum MaterialsNames {
  skier = 'skier',
  skierOpponent = 'skier_opponent',
  hill = 'Atlas1',
  ads = 'ads',
  flags = 'flags',
  track = 'track',
  // people = 'People',
  staticPeople = 'StaticPeople',
  // onlyVertexColor = 'OnlyVertexColor',
  audience = 'audience',
  ground = 'ground',
  transparentAds = 'transparentAds',
  lightBeams = 'LightBeams',
  emmisive = 'Emmisive',
  trackLinesFragments = 'TrackLinesFragments'
  /*
   * impact = 'impact',
   * startAds = 'StartAds'
   */
}

/** Mena vsetkych animacii hraca */
export enum PlayerAnimationsNames {
  lunge = 'lunge',
  prepare = 'prepare',
  prestart = 'prestart',
  falseStart = 'false start',
  falseStartEnd = 'false start end',
  start = 'start',
  sprint = 'sprint',
  neutral = 'neutral',
  happy = 'happy',
  arrival = 'prichod',
  flatPlane = 'rovinka',
  turn = 'zakruta'
}

/** Konfig pre zakladne fyzicke veci */
export interface GameConfig {
  linearDamping: number
  playerMass: number
  frictionHillPlayer: number
  restitutionHillPlayer: number
  frictionEquationRelaxationHillPlayer: number
  frictionEquationStiffnessHillPlayer: number
  contactEquationRelaxationHillPlayer: number
  contactEquationStiffnessHillPlayer: number
}

/**
 * Specialne data z init requestu
 */
export interface SpecialDataFromInit {

  split: number[],
  worldRecord: number

}


/** Typy mien lubovolnych assetov */
export type AssetsConfigsNamesTypes = MaterialsNames | TexturesNames | ModelsNames

/** Typy konfigov assetov */
export type AssetsConfigsTypes = MaterialDataObject | LoaderDataTypes

/** Mena assetov pre konfigy */
export enum AssetsConfigsNames {

  textures = 'textures',
  models = 'models',
  materials = 'materials'

}

/** Konfigy assetov (pre vsetky mena) */
export type AssetsConfigs = { [key in AssetsConfigsNames]: AssetsConfigsTypes }
