import { THREE } from '@powerplay/core-minigames'

/** Konfig pre nastavenie fyziky a aj celej hry */
export const gameConfig = {

  /** Pocet pokusov */
  numberOfAttempts: 1,

  /**
   * offset prilepenia hraca na svah - iba na starte
   */
  playerModelOffsetStart: 0.1,

  /**
   * offset prilepenia hraca na svah
   */
  playerModelOffset: 0.3,

  /** nastavenie kamery aplikovane po starte. */
  cameraConfig: {

    // ci chceme zmeny aplikovat
    enabled: true,

    /*
     * ako daleko od hraca ma byt kamera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealOffset: new THREE.Vector3(0, 2, -2.5),

    /*
     * ako daleko od hraca ma byt bod na ktory sa kamera pozera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealLookAt: new THREE.Vector3(0, 0, 2),

    /*
     * ako rychlo ma kamera nasledovat hraca
     * typ number | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    coefSize: undefined,

    // velkost lerpu pri zmene kamery
    changeLerp: 1,

    // ci je pozicia kamery staticka
    isStaticMovement: false

  },

  /**
   * starting position
   */
  startPosition: new THREE.Vector3(-56.5, 0.1220703125, 32.455665588378906),

  /**
   * starting position of opponent
   */
  startPositionOponent: new THREE.Vector3(-56.5, 0.1220703125, 27.274503707885742),

  /**
   * defaultna rychlost animacii
   */
  defaultAnimationSpeed: 1 / 2,

  /**
   * Ci mam aktivny skip do ciela a na akej pozicii to zacina
   *
   * TODO - pozicia ma byt pozicia na krivke
   */
  skipToFinish: {

    active: false
    // position: new THREE.Vector3(11, 1, -20)

  },

  /**
   * automaticky pohyb
   */
  autoMove: {

    // zi je zapnuty
    isEnabled: false,

    // po kolkych framoch reagujeme pri starte
    startFrames: 3,

    // ako casto sa budu davat spravne inputy pri rozbehu
    runUpFrames: 5

  },

  /** fix aby zacinali na cervenej ciare  */
  startPercentOnCurve: 0.0007,

  /** fix pozicie y, aby neboli vo vzduchu */
  yCorrection: 0.12

}
