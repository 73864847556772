import {
  fpsManager,
  gsap
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'
import { DisciplinePhases } from '../types'
import { stateManager } from '../StateManager'
import {
  runningPhaseConfig,
  velocityConfig
} from '../config'

/**
 * Manager rychlosti hraca
 */
export class SpeedManager {

  /** maximalna rychlost */
  private topSpeed = 17

  /** rychlost zrychlovania pri rozbehu */
  private speedAutoIncreaseRunUp = 0.05

  /** tween na zobrazenie rychlosti */
  private speedmeterTween!: gsap.core.Tween

  /** ci je speedManager aktivny */
  private active = false

  /** momentalna rychlost v m/s */
  private actualSpeed = velocityConfig.startSpeed

  /** min speed na konci rozbehu, pod tuto rychlost nepojde pocas korculovania */
  private minSpeed = 0

  /** cruise speed vypocitana dopredu */
  private cruiseSpeed = this.topSpeed - velocityConfig.cruiseCoef

  /** cast rychlosti z rozbehoveho baru */
  private runUpBarSpeedPart = 0

  /** ci ma zrychlovat pri failed starte */
  public failedStartAcceleration = false

  /** percento rychlosti */
  public percentSpeed = this.actualSpeed / this.topSpeed

  /**
   * nastavime aktualnu rychlost
   * @param velocity - Vektor rychlosti
   * @param canGoToZero - Ci moze rychlost klesnut na 0
   */
  public setActualSpeed(speed: number, canGoToZero = false): void {

    this.actualSpeed = speed
    if (canGoToZero) {

      if (this.actualSpeed < 0) this.actualSpeed = 0

    } else if (this.actualSpeed < this.minSpeed) {

      this.actualSpeed = this.minSpeed

    }

    if (this.actualSpeed > this.topSpeed) {

      this.actualSpeed = this.topSpeed

    }

    this.percentSpeed = this.actualSpeed / this.topSpeed

  }

  /**
   * Set topSpeed podla hracovej sily
   * @param attribute - hracova sila
   */
  public setTopSpeedFromAttribute(attribute: number): void {

    const { topSpeed, limit } = velocityConfig.speedCalculationCoefs
    if (attribute <= limit) {

      this.topSpeed = topSpeed.low.min + (topSpeed.low.multiplier * attribute / limit)

    } else {

      this.topSpeed =
            topSpeed.high.min + (topSpeed.high.multiplier * (attribute - limit) / limit)

    }
    this.cruiseSpeed = this.topSpeed - velocityConfig.cruiseCoef

  }

  /**
   * Set speedAutoIncreaseRunUp podla hracovej sily
   * @param attribute - hracova sila
   */
  public setSpeedAutoIncreaseRunUpFromAttribute(attribute: number): void {

    const { runUp, limit } = velocityConfig.speedCalculationCoefs
    if (attribute <= limit) {

      this.speedAutoIncreaseRunUp = runUp.low.min + (runUp.low.multiplier * attribute / limit)

    } else {

      this.speedAutoIncreaseRunUp =
            runUp.high.min + (runUp.high.multiplier * (attribute - limit) / limit)

    }

  }

  /**
   * Zmena aktualnej rychlosti
   * @param speed - rychlost o ktoru sa zmeni aktualna
   */
  public changeSpeed(speed: number): void {

    this.setActualSpeed(this.actualSpeed + speed)

  }

  /**
   * Zistenie, ci ma hrac "cruise" rychlost a vyssiu
   * @returns True, ak ma
   */
  public isCruise(): boolean {

    return this.actualSpeed >= this.cruiseSpeed

  }

  /**
   * zobrazenie rychlosti v ui
   */
  public displayActualSpeed(): void {

    if (disciplinePhasesManager.getActualPhase() !== DisciplinePhases.runUp ||
        !this.actualSpeed) return
    if (this.speedmeterTween) this.speedmeterTween.progress(1)

    const speedData = {
      visible: true,
      speed: this.actualSpeed.toFixed(2)
    }
    stateManager.updateSpeedData(speedData)

    this.speedmeterTween = gsap.to({}, {
      duration: 2,
      onComplete: () => {

        stateManager.hideSpeedVisibility()

      }
    })

  }

  /**
   * Vracia rychlost v metroch za sekundu
   * @returns actualSpeed m/s
   */
  public getActualSpeed(): number {

    return this.actualSpeed

  }

  /**
   * Vracia rychlost v metroch za frame
   * @returns actualSpeedPerFrame m/f
   */
  public getActualSpeedPerFrame(): number {

    return this.actualSpeed / fpsManager.fpsLimit

  }

  /**
   * Setter active
   * @param active - active
   */
  public setActive(active: boolean): void {

    this.active = active

  }

  /**
   * Vratenie, ci je aktivna rychlost
   * @returns True, ak je aktivna
   */
  public isActive(): boolean {

    return this.active

  }

  /**
   * automaticke zrychlovanie pri rozbehu
   */
  public autoIncreaseRunUp(): void {

    this.changeSpeed(this.speedAutoIncreaseRunUp)

  }

  /** automaticke znizovanie rychlosti pri cruise */
  public autodecreaseImpulse(): void {

    if (!this.isCruise()) return

    this.changeSpeed(-velocityConfig.speedAutoDecreaseValue)

  }

  /**
   * update speed podla aktualne barPower
   * @param barPower - barPower
   */
  public resolveSpeedFromBarPower(barPower: number): void {

    const { minValueBonusSpeed, maxValueBonusSpeed } = velocityConfig.speedBar

    /*
     * min speed je 0, max speed je 3
     * teda 0% baru je actualspeed + 0, 100% baru je actualSpeed + 3
     */
    const newAdditionalSpeed = (
      (maxValueBonusSpeed - minValueBonusSpeed) * barPower
    ) + minValueBonusSpeed

    /** odpocitame predchadzajucu hodnotu rychlosti z baru */
    /** pripocitame novu hodnotu rychlosti z baru */
    this.changeSpeed(-this.runUpBarSpeedPart + newAdditionalSpeed)

    this.runUpBarSpeedPart = newAdditionalSpeed

  }

  /**
   * nastavenie rychlosti podla uspesnosti impulzu
   * @param barDuration - barDuration
   * @param pressedFrameCounterCorrect - correctFrames
   */
  public resolveImpulsePower(barDuration: number, pressedFrameCounterCorrect: number): void {

    const percent = pressedFrameCounterCorrect / barDuration
    const {
      idealSpeedIncreaseLow, idealSpeedIncreaseMedium, idealSpeedIncreaseHigh
    } = runningPhaseConfig

    let speedFromPower = 0
    if (percent > 0.8) {

      speedFromPower = 0.5 + idealSpeedIncreaseHigh * (percent - 0.8)

    } else if (percent > 0.5) {

      speedFromPower = 0.2 + idealSpeedIncreaseMedium * (percent - 0.5)

    } else {

      speedFromPower = idealSpeedIncreaseLow * percent

    }
    if (this.topSpeed - this.actualSpeed < 1) {

      speedFromPower = speedFromPower * (this.topSpeed - this.actualSpeed)

    }

    this.changeSpeed(speedFromPower)

  }

  /**
   * spomalovanie po prechode ciela
   */
  public slowDownAfterFinishLine(): void {

    this.changeSpeed(-velocityConfig.finishSlowDownCoef)

  }

  /**
   * Nastavenie aktualnej rychlosti ako najnizsiu moznu do buducna
   */
  public setMinSpeed(): void {

    this.minSpeed = this.actualSpeed

  }

  /**
   * spomalovanie po zlom starte
   */
  public manageFailedStart(): void {

    let changeSpeedValue = -velocityConfig.failedStartSlowDownCoef
    if (this.failedStartAcceleration) {

      changeSpeedValue = velocityConfig.failedStartAccelerationCoef

    }

    this.changeSpeed(changeSpeedValue)

  }

  /** reset */
  public reset(): void {

    this.actualSpeed = velocityConfig.startSpeed
    this.minSpeed = 0
    this.active = false
    this.runUpBarSpeedPart = 0

  }

}
