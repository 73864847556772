import { velocityConfig } from '@/app/config'
import { Sides } from '@/app/types'
import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface ImpulseInputState {
  show: boolean,
  side: Sides,
  frames: {
    total: number,
    correct: number,
    incorrect: number
  },
  barDuration: number,
  leftText: string,
  rightText: string,
  successImpulsesCount: number,
  barWidth: number
}

const initialState = () => ({
  show: false,
  side: Sides.LEFT,
  frames: {
    total: 0,
    correct: 0,
    incorrect: 0
  },
  barDuration: 0,
  leftText: '',
  rightText: '',
  successImpulsesCount: 0,
  barWidth: velocityConfig.impulseBar.defaultBarWidth
})

const impulseInputState: Module<ImpulseInputState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getImpulseInputState: (moduleState: ImpulseInputState) => moduleState,
    isShown: (moduleState: ImpulseInputState) => moduleState.show,
    getTexts: (moduleState: ImpulseInputState) => {

      return { left: moduleState.leftText,
        right: moduleState.rightText }

    },
    getSuccessImpulsesCount: (moduleState: ImpulseInputState) =>
      moduleState.successImpulsesCount
  },

  mutations: {
    RESET: (moduleState: ImpulseInputState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: ImpulseInputState, newState: ImpulseInputState) => {

      if (newState.show !== undefined) moduleState.show = newState.show
      if (newState.side !== undefined) moduleState.side = newState.side
      if (newState.frames !== undefined) moduleState.frames = newState.frames
      if (newState.barDuration !== undefined) {

        moduleState.barDuration = newState.barDuration

      }
      if (newState.barWidth !== undefined) moduleState.barWidth = newState.barWidth

    },
    SET_SIDES: (moduleState: ImpulseInputState, side: Sides) => {

      moduleState.side = side

    },
    SET_VISIBLE: (moduleState: ImpulseInputState, visible: boolean) => {

      moduleState.show = visible

    },
    SET_FRAMES: (
      moduleState: ImpulseInputState,
      frames: {
        total: number,
        correct: number,
        incorrect: number
      }
    ) => {

      moduleState.frames = frames

    },
    SET_LEFT_TEXT: (moduleState: ImpulseInputState, leftText: string) => {

      moduleState.leftText = leftText

    },
    SET_RIGHT_TEXT: (moduleState: ImpulseInputState, rightText: string) => {

      moduleState.rightText = rightText

    },
    SET_SUCCESS_IMPULSES_COUNT: (moduleState: ImpulseInputState, successImpulsesCount: number) => {

      moduleState.successImpulsesCount = successImpulsesCount

    }
  }
}

export default impulseInputState
