<template>
  <div
    class="under-menu-component"
    :style="transformCoef"
  >
    <div class="component-item">
      <time-keeper-component />
    </div>
  </div>
</template>

<script lang="ts">
// External Package codes
import { mapGetters } from 'vuex'
import { WindowAspect } from '@powerplay/core-minigames-ui'

// Game Components
import TimeKeeperComponent from '@/components/TimeKeeperComponent/index.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UnderMenuComponent',
  components: {
    TimeKeeperComponent
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
      bulletsTargetsVisible: 'BulletsTargetsBoxState/getDisplayBulletsTargets',
      displayShooting: 'BulletsTargetsBoxState/getDisplayBulletsTargets'
    }),
    transformCoef() {

      return {
        transform: `scale(${this.scaleCoef})`,
        'transform-origin': '100% 0'
      }

    }
  },
  methods: {

  }
})

</script>

<style lang="less" scoped>

.under-menu-component{
    position: absolute;
    right: 1%;
    top: 17%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .component-item {
        position: relative;
        margin-bottom: 15px;
    }

}

</style>
