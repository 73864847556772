import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface TimeState {
  penaltySeconds: number,
  timeWithPenalty: string,
  time: string
}

const initialState = () => ({
  penaltySeconds: 0,
  timeWithPenalty: '0.0',
  time: '0.0'
})

const timeState: Module<TimeState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getTimeState: (moduleState: TimeState) => moduleState,
    getPenaltySeconds: (moduleState: TimeState) => moduleState.penaltySeconds,
    getTimeWithPenalty: (moduleState: TimeState) => moduleState.timeWithPenalty,
    getTime: (moduleState: TimeState) => moduleState.time
  },

  mutations: {
    RESET: (moduleState: TimeState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: TimeState, newState: TimeState) => {

      moduleState.penaltySeconds = newState.penaltySeconds
      moduleState.timeWithPenalty = newState.timeWithPenalty
      moduleState.time = newState.time

    }
  }
}

export default timeState
