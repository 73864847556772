<template>
  <section class="positioner">
    <section
      class="action-sides-container"
    >
      <arrow-animation
        position="bottom"
        class="bottom-right"
        style="position: fixed; width: 580px; height: 460px; bottom: 0; right: 0"
      />
      <arrow-animation
        position="bottom"
        class="bottom-left"
        style="position: fixed; width: 580px; height: 460px; bottom: 0; left: 0"
      />
      <template v-if="!showImpulseBar">
        <div
          class="left-side"
          :style="transformCoef"
        >
          <mobile-button
            class="left-side-button bottom-left"
            type="speed-up-skating-l"
            glow
            :is-scaled="false"
          />
        </div>
        <div
          class="right-side"
          :style="transformCoef"
        >
          <mobile-button
            class="right-side-button bottom-right"
            type="speed-up-skating-r"
            glow
            :is-scaled="false"
          />
        </div>
      </template>

      <template v-else>
        <movement-sides
          style="width: 100%"
          glow
          ignore-safe-zones
        />
      </template>
    </section>
  </section>
</template>
<script lang="ts">
import { mapGetters } from 'vuex'
import {
  MobileButton,
  ArrowAnimation,
  WindowAspect,
  MovementSides
} from '@powerplay/core-minigames-ui'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TutorialButtons',
  components: {
    MobileButton,
    ArrowAnimation,
    MovementSides
  },
  mixins: [WindowAspect],
  data() {

    return {
      tasks: [],
      blockedEndAction: false,
      wasTypewriting: false
    }

  },
  computed: {
    ...mapGetters({
      tutorialButtons: 'TutorialState/getTutorialButtons',
      sprintBar: 'GamePhaseState/getSprintBar',
      isAllowedToTuck: 'GamePhaseState/getIsAllowedToTuck',
      gameSettingsState: 'GameSettingsState/getGameSettingsState',
      isLeft: 'InputsState/isLeft',
      isHorizontal: 'MovementState/getIsHorizontal',
      isTypewriting: 'TutorialCoreState/getTypeWriter',
      showImpulseBar: 'TutorialState/getShowImpulseBar'
    }),
    basicStyle() {

      return {
        'align-items': 'flex-end',
        width: '290px',
        height: '200px',
        padding: '20px'
      }

    },
    transformCoef() {

      return { transform: `scale(${this.scaleCoef})` }

    }
  },
  methods: {
  }
})

</script>

<!-- TU nemoze byt scoped, pokial bude riesene .small-action-button-wrapper v tomto file -->
<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.action-sides-container {
    height: 580px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;

    &.disabled {
        opacity: 0.7;
    }

    .power-mobile-button{
        .button-element{
            .image{
                height: 100px;
                width: 100px;
                background-size: cover;
                left: 50%;
                margin-left: -50px;
            }
        }
    }

    .left-side{
        display: flex;
        position: fixed;
        left: 0;
        bottom: 0;
        transform-origin: bottom left;

        .left-side-button {
            .button-element {
                .image {
                    margin-left: -30px;
                }
            }
        }
    }

    .right-side{
        display: flex;
        position: fixed;
        right: 0;
        bottom: 0;
        transform-origin: center;
        transform-origin: bottom right;

        .right-side-button {
            .button-element {
                .image {
                    margin-left: 20px;
                }
            }
        }
    }

}
.bottom-right {
    transform-origin: bottom right;
}
.bottom-left {
    transform-origin: bottom left;
}
</style>
