
import {
  game,
  modes,
  THREE
} from '@powerplay/core-minigames'

/**
 * Trieda pre kopec
 */
export class Hill {

  /** Mesh kopca */
  public hillMesh!: THREE.Mesh

  /**
   * Vytvorenie kopca
   */
  public create(): void {

    console.log('VYTVARAM HILL....')

    /*
     * const racelinePlayer = game.getObject3D('Raceline_Player')
     * racelinePlayer.visible = false
     * const racelinePlayerFinish = game.getObject3D('Raceline_Player_Finish')
     * racelinePlayerFinish.visible = false
     */
    if (modes.isDailyLeague() || modes.isTrainingMode() || modes.isTutorial()) {

      const racelineOpponent = game.getObject3D('Raceline_Opponent')
      racelineOpponent.visible = false
      const racelineOpponentFinish = game.getObject3D('Raceline_Opponent_Finish')
      racelineOpponentFinish.visible = false

    }

    console.log('HILL vytvoreny....')

  }

}

export const hill = new Hill()
