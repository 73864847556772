
/**
 * Specialny konfig pre hracove animacie
 */
export const playerAnimationConfig = {

  /**
   * Zakladny cas pre crossfade
   */
  defaultCrossfadeTime: 0.02,

  /**
   * Rychlost animacii pre zmenu z/na zakrutu
   */
  crossfadeTimeCurveToggle: 0.5

}
