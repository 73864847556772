import {
  player,
  playerAnimationManager
} from '../../entities/player'
import {
  AudioNames,
  AudioGroups,
  type DisciplinePhaseManager,
  TutorialEventType,
  PlayerTypes,
  PlayerAnimationsNames
} from '../../types'
import store from '@/store'
import {
  timeManager,
  playersManager,
  fpsManager,
  cameraManager,
  TimesTypes,
  CameraStates,
  modes,
  gsap,
  audioManager,
  trainingManager
} from '@powerplay/core-minigames'
import { endManager } from '@/app/EndManager'
import { inputsManager } from '@/app/InputsManager'
import {
  cameraConfig,
  finishPhaseConfig
} from '@/app/config'
import { opponent } from '@/app/entities/Opponent/Opponent'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'

/**
 * Trieda fazy pre dojazd v cieli (resp naburanie)
 */
export class FinishPhaseManager implements DisciplinePhaseManager {

  /** pocet zle prejdenych branok */
  #successfulGates = 0

  /** ci uz skoncila kamera na konci */
  public cameraFinished = false

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** tween na ukoncenie fazy po animacii */
  private finishPhaseTween !: gsap.core.Tween

  /** ci faza skoncila */
  private ended = false

  /** kolko framov preslo od zaciatku fazy */
  private framesInPhase = 0

  /** kolko framov preslo od zaciatku podfazy movementu */
  private framesInLocalPhase = 0

  /** audio divakov podla emocie */
  private audienceAudio !: AudioNames

  /** Aktualna rychlost pohybu */
  private actualSpeed = 0

  /** Aktualny step v linearnej faze pohybu */
  private linearDecreaseStep = 0

  /** pole objektov faz pohybu v cieli */
  private movementPhasesUpdates: (() => unknown)[] = []

  /** tween na zmenu UI stavu */
  private changeUiStateTween!: gsap.core.Tween

  /** tween na skoncenie po kamere */
  private cameraSkippableTween!: gsap.core.Tween

  /** ci je mozne skipnut */
  private skippable = false

  /** kolko po starte mame zobrazit finish top box */
  private SHOW_FINISH_TOP_BOX_SECONDS = 2

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * getter
   */
  public get successfulGates(): number {

    return this.#successfulGates

  }

  /**
   * setter
   */
  public set successfulGates(newValue: number) {

    this.#successfulGates = newValue

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {
    //
  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    fpsManager.pauseCounting()
    tutorialFlow.eventActionTrigger(TutorialEventType.finish)

    if (modes.isTutorial()) return

    this.reset()
    this.preparePhase()

    const playerTime = timeManager.getGameTimeWithPenaltyInSeconds(undefined, undefined, 3)

    playersManager.setPlayerResultsById(
      opponent.uuid,
      opponent.finalTime,
      undefined,
      false
    )

    player.finalTime = player.triggersManager.splitTimeManager.calculateFinalSeconds(
      playerTime,
      player.hillLinesManager.getFinishPercent(),
      player.hillLinesManager.getLastPercent(),
      1,
      player.hillLinesManager.oneMeterInPercent,
      true,
      PlayerTypes.player
    )

    playersManager.setPlayerResults(player.finalTime)

    player.triggersManager.splitTimeManager.checkActualSplit(
      player.finalTime,
      PlayerTypes.player,
      opponent.uuid,
      true,
      opponent.triggersManager.splitTimeManager.opponentPassed,
    )

    playersManager.setStandings(2)

    console.log('Finalny cas hrac', player.finalTime)

    console.warn('finish phase started')
    store.commit('InputsState/SET_VISIBLE', false)
    store.commit('HeartRateState/SET_VISIBLE', false)
    store.commit('GamePhaseState/SET_SMALL_ACTION', false)
    store.commit('SplitTimeState/SET_SHOW_FINISH_SPLIT', true)
    store.commit('SpeedmeterState/SET_VISIBILITY', false)

    store.commit('UiState/SET_STATE', {
      showTimeKeeper: false,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

    gsap.to({}, {
      onComplete: () => {

        player.finishAction()
        this.skippable = true
        cameraManager.setState(CameraStates.disciplineOutro)
        cameraManager.playTween()
        this.cameraSkippableTween = gsap.to({}, {
          onComplete: () => {

            this.cameraFinished = true
            if (modes.isTutorial() || modes.isTrainingMode() || modes.isDailyLeague()) {

              this.prepareFinishTable()
              this.finishPhase()

            }

          },
          duration: cameraConfig.tweenSettingsForCameraStates[
            CameraStates.disciplineOutro
          ].duration + 1
        })

      },
      duration: 1
    })

    this.changeUiStateTween = gsap.to({}, {
      duration: this.SHOW_FINISH_TOP_BOX_SECONDS,
      onComplete: () => {

        if (modes.isTrainingMode()) {

          store.commit('TrainingState/SET_HIGH_SCORE', {
            newHighScore: Math.ceil(trainingManager.getNewPotentialHighScore()),
            showNewHighScore: trainingManager.isNewHighScore()
          })

        }
        store.commit('UiState/SET_STATE', {
          showTimeKeeper: false,
          showFinishTopBox: (!modes.isTutorial() && !modes.isTrainingMode()),
          showTrainingLayout: modes.isTrainingMode(),
          isTraining: modes.isTrainingMode()
        })
        this.setFinishTopBoxData()

      }
    })
    this.playCommentatorAudio()

  }

  /**
   * nastavime data pre top box
   */
  private setFinishTopBoxData(): void {

    if (modes.isDailyLeague() && !playersManager.isPlayerImproved()) return

    const personalBest = playersManager.getPlayer().personalBest
    const finalTime = player.finalTime
    const worldRecord = finalTime === store.getters['SplitTimeState/getWr']
    const newWorldRecord = finalTime < store.getters['SplitTimeState/getWr']
    const timeFormat = timeManager.getTimeInFormatFromSeconds(finalTime)
    const position = playersManager.getPlayerActualPosition()

    const showFirstBox = position < 4
    const showSecondBox = player.finalTime <= personalBest

    store.commit('FinishTopBoxState/SET_STATE', {
      showFirstBox: showFirstBox,
      showSecondBox: showSecondBox,
      firstPlace: position === 1,
      personalBest: finalTime === personalBest && !worldRecord && !newWorldRecord,
      newPersonalBest: finalTime < personalBest && !worldRecord && !newWorldRecord,
      worldRecord: worldRecord,
      newWorldRecord: newWorldRecord,
      time: timeFormat,
      position: position
    })

  }

  /**
   * pustime komentatora
   */
  private playCommentatorAudio(): void {

    if (modes.isTutorial() || modes.isTrainingMode()) return

    const pos = playersManager.getPlayerActualPosition()

    let audio = AudioNames.commentFinish4

    if (pos === 1) {

      audio = AudioNames.commentFinish1

    } else if (pos <= 3) {

      audio = AudioNames.commentFinish2

    } else if (pos <= (modes.isDailyLeague() || modes.isBossCompetition() ? 10 : 5)) {

      audio = AudioNames.commentFinish3

    }

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(audio)

  }

  /**
   * zapneme audience audio
   */
  private startAudienceAudio(): void {

    // audioManager.stopAudioByName(AudioNames.audienceHype)

    this.audienceAudio = playerAnimationManager.animationRunning === PlayerAnimationsNames.happy ?
      AudioNames.audienceYay :
      AudioNames.audienceSad

    audioManager.play(this.audienceAudio)
    audioManager.changeAudioVolume(this.audienceAudio, 1)

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.framesInPhase += 1
    this.skipPhase()

    player.speedManager.slowDownAfterFinishLine()

    // po x frameoch nastavujeme animaciu konca
    if (this.framesInPhase === finishPhaseConfig.startAnimationEndAfterFrames) {

      player.isEnd = true
      // finishPhase.setFinishPhaseTween()

    }
    if (playerAnimationManager.isEndEmotionSet && !this.audienceAudio) {

      this.startAudienceAudio()

    }
    if (opponent.finished && this.cameraFinished) {

      this.prepareFinishTable()
      this.finishPhase()

    }

  }

  /**
   * Zobrazenie finalnej tabulky
   */
  private prepareFinishTable() {

    timeManager.setActive(TimesTypes.game, false)
    store.commit('SplitTimeState/SET_SHOW_FINISH_SPLIT', false)
    // data pre tabulku
    playersManager.setStandings()
    console.log('STANDINGS', playersManager.getStandings())
    store.commit('TableState/SET_DATA', playersManager.getStandings())

  }

  /**
   * skipnutie fazy
   */
  public skipPhase(): void {

    if (opponent.isActive && !opponent.finished) return
    if (!this.skippable || !inputsManager.actionPressed) return

    this.prepareFinishTable()
    this.cameraSkippableTween?.kill()
    this.finishPhase()

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    if (this.ended) return
    this.ended = true

    if (this.finishPhaseTween) this.finishPhaseTween.kill()
    if (this.changeUiStateTween) this.changeUiStateTween.kill()

    store.commit(
      'GameplayTableState/SET_TABLES_VISIBILITY',
      {
        showTables: false
      }
    )

    store.commit('UiState/SET_STATE', {
      showTimeKeeper: false,
      showFinishTopBox: (!modes.isTutorial() && !modes.isTrainingMode()),
      showTrainingLayout: false,
      isTraining: modes.isTrainingMode()
    })

    fpsManager.pauseCounting()
    endManager.sendLogEnd()
    endManager.sendSaveResult()

    console.warn('finish phase ended')
    this.callbackEnd()

  }

  /**
   * sets tween to finish phase
   */
  public setFinishPhaseTween(): void {

    this.finishPhase()

  }

  /**
   * reset fazy
   */
  public reset(): void {

    this.framesInPhase = 0
    this.successfulGates = 0
    this.ended = false
    if (this.changeUiStateTween) this.changeUiStateTween.kill()

  }

}
