import {
  audioManager,
  gsap
} from '@powerplay/core-minigames'
import {
  AudioGroups,
  AudioNames,
  PlayerTypes,
  Sides
} from '../types'

/**
 * metody ktore pomahaju pri spustani zvukov
 */
export class AudioHelper {

  // bol uz zahraty zvuk vystrelu
  public wasStartShot = false

  // bol uz zahraty zvuk vystrelov pri false starte
  public wereFalseStartShots = false

  /** Tween pre audience hype po vystrele */
  private tweenAudienceHype?: gsap.core.Tween

  /** posledna strana korcule na lade */
  private lastHitIceSide = {
    [PlayerTypes.player]: Sides.RIGHT,
    [PlayerTypes.opponent]: Sides.RIGHT,
  }

  /** Casy pre dotyk korcule s ladom */
  private readonly TIMES_TO_CHECK = {
    [Sides.LEFT]: 0.18,
    [Sides.RIGHT]: 0.43
  }

  /** Maximalne casy pre kontrolu dotykov korcul s ladom */
  private readonly MAX_TIMES = {
    [Sides.LEFT]: 0.43,
    [Sides.RIGHT]: 0.6
  }

  /**
   * Zahratie zvuku prveho vystrelu
   */
  public playFirstShotAudio(): void {

    if (!this.wereFalseStartShots) {

      audioManager.play(AudioNames.startShot)
      this.wasStartShot = true
      this.tweenAudienceHype = gsap.to({}, {
        duration: 0.5,
        onComplete: () => {

          audioManager.play(AudioNames.audienceHype)
          audioManager.changeAudioVolume(AudioNames.audienceHype, 0.5)

        }
      })

    }

  }

  /**
   * Zahratie zvuku druheho vystrelu alebo dvoch vystrelov naraz
   */
  public playFalseStartAudio(): void {

    this.wereFalseStartShots = true
    const audioName = this.wasStartShot ? AudioNames.startShot : AudioNames.falseStartShot

    gsap.to({}, {
      onComplete: () => {

        audioManager.stopAudioByName(audioName)
        audioManager.play(audioName)

      },
      duration: audioName === AudioNames.startShot ? 1 : 0.2
    })

    gsap.to({}, {
      duration: 0.5,
      onComplete: () => {

        audioManager.play(AudioNames.audienceSad)

      }
    })

    this.tweenAudienceHype?.kill()
    audioManager.stopAudioByName(AudioNames.audienceHype)

  }

  /**
   * Meneovanie zvuku pre dotyk s ladom
   * @param audioNameLeft - Nazov zvuku pre lavu korculu
   * @param audioNameRight - Nazov zvuku pre pravu korculu
   * @param time - Aktualny time animacie
   */
  public manageSkateHitIceSound(
    audioNameLeft: AudioNames,
    audioNameRight: AudioNames,
    time: number,
    type: PlayerTypes
  ): void {

    const actualSideToCheck = this.lastHitIceSide[type] === Sides.LEFT ?
      Sides.RIGHT :
      Sides.LEFT

    if (
      time > this.TIMES_TO_CHECK[actualSideToCheck] &&
      time < this.MAX_TIMES[actualSideToCheck]
    ) {

      this.lastHitIceSide[type] = actualSideToCheck
      const audioName = actualSideToCheck === Sides.LEFT ? audioNameLeft : audioNameRight
      audioManager.stopAudioByName(audioNameLeft)
      audioManager.stopAudioByName(audioNameRight)
      const group = type === PlayerTypes.player ? AudioGroups.movementPlayer : AudioGroups.movementOpponent
      audioManager.stopAudioByGroup(group)
      audioManager.play(audioName)
      // console.log('play sound', audioName)

    }

  }

  /**
   * updatujeme kazdy frame pri niektorych disciplinach
   * kde potrebujeme ratat framy pre spustanie zvukov
   */
  public update(): void {

    // zatial nic

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.wasStartShot = false
    this.wereFalseStartShots = false

  }

}

export const audioHelper = new AudioHelper()
