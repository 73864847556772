import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface DirectionsState {
  show: boolean,
  player: number,
  hill: number,
  movement: number,
  total: number,
  wrongWay: boolean
}

const initialState = () => ({
  show: false,
  player: -9999,
  hill: -9999,
  movement: -9999,
  total: -9999,
  wrongWay: false
})

const directionsState: Module<DirectionsState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getDirectionsState: (moduleState: DirectionsState) => moduleState,
    getWrongWay: (moduleState: DirectionsState) => moduleState.wrongWay
  },

  mutations: {
    RESET: (moduleState: DirectionsState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: DirectionsState, newState: DirectionsState) => {

      if (newState.show) moduleState.show = newState.show
      if (newState.player || newState.player === 0) moduleState.player = newState.player
      if (newState.hill || newState.hill === 0) moduleState.hill = newState.hill
      if (newState.movement || newState.movement === 0) {

        moduleState.movement = newState.movement

      }
      if (newState.total || newState.total === 0) moduleState.total = newState.total

    },
    SET_WRONG_WAY: (moduleState: DirectionsState, wrongWay: boolean) => {

      moduleState.wrongWay = wrongWay

    }
  }
}

export default directionsState
