import type { AnimationsSettingRepository } from '@powerplay/core-minigames'
import { PlayerAnimationsNames } from '../types'
/**
 * Konfig pre animacie
 */
export const animationsConfig: AnimationsSettingRepository = {

  [PlayerAnimationsNames.lunge]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.arrival]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.flatPlane]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.turn]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepare]: {
    loop: true,
    starting: true
  },
  [PlayerAnimationsNames.start]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.neutral]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.happy]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.prestart]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.falseStart]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.falseStartEnd]: {
    loop: true,
    starting: false
  }
}
