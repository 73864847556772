/**
 * trieda pre spravu konstant pre audio
 */
export const audioGameConfig = {

  /** hranica pri ktorej davame roznych komentatorov na split time */
  splitTimeDifference: 0.1,

  /** v kolkych % trate sa ma spustit komentator laneSwitch */
  laneSwitchTrigger: 0.5,

  /** v kolkych % trate sa ma spustit komentator preFinish */
  preFinishTrigger: 0.80,

  /** v kolkych % trate sa ma spustit zvysenie hlasitosti hype audience */
  hypeIncreaseVolumeTrigger: 0.85

}
