
/**
 * Konfig pre tutorial
 */
export const tutorialConfig = {

  /** hodnota baru od ktorej je splneny tutorial task pri rozbehu */
  runUpQualitySuccess: 0.5,

  /** pocet impulzov na splnenie tutorial task */
  impulsesCorrectSuccess: 10
}
