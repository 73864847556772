/**
 * Mena sekcie
 */
export enum SectionNames {
  handCheck = 'handCheck',
  startSection = 'startSection',
  startSectionSecond = 'startSectionSecond',
  startSectionThird = 'startSectionThird',
  afterRunUpSection = 'afterRunUpSection',
  startRunningSection = 'startRunningSection',
  startOfSecondCurveSection = 'startOfSecondCurveSection',
  finishSection = 'finishSection',
  endSection = 'endSection',
  /*
   * startSectionEight = 'startSectionEight',
   * startSectionNinth = 'startSectionNinth',
   * startSectionTenth = 'startSectionTenth',
   * startSectionEleventh = 'startSectionEleventh',
   * startSectionTwelfth = 'startSectionTwelfth',
   * startSectionThirteenth = 'startSectionThirteenth',
   * startSectionFourteenth = 'startSectionFourteenth'
   */
}

type NameKeys = keyof typeof SectionNames;
type NameKeyFields = { [key in NameKeys]: string }

export interface UiState extends NameKeyFields {
  NameKeyFields: () => void
}

export enum TutorialEventType {
  failedStart = 'failedStart',
  goodStart = 'goodStart',
  afterRunUp = 'afterRunUp',
  startRunning = 'startRunning',
  startOfSecondCurve = 'startOfSecondCurve',
  finish = 'finish',
  awaitingEvent = 'awaitingEvent'
}

export enum TutorialObjectiveIds {
  start = 'tutorialTask5-1',
  acceleration = 'tutorialTask5-2',
  impulses = 'tutorialTask5-3',
  finish = 'tutorialTask5-4'
}
