/** Konfig pre nastavenie rychlosti hraca */
export const velocityConfig = {

  /** Speed bar veci */
  speedBar: {

    /** minimalna hodnota speed baru */
    minValue: 0,

    /** maximalna hodnota speed baru */
    maxValue: 100,

    /** hodnota bonusu k rychlosti pri min value */
    minValueBonusSpeed: 0,

    /** hodnota bonusu k rychlosti pri max value */
    maxValueBonusSpeed: 3,

    /** hodnota pri znizovani hodnoty speed baru auto */
    speedBarAutoDecreaseValue: 2,

    /** ako casto sa automaticky znizuje */
    speedBarAutoDecreaseFrames: 1,

    /** ako dlho po kliku ma byt input zmrazeny */
    speedBarInputFreeze: 0,

    /** config stepAdd */
    stepAddConf: [
      40,
      70,
      90,
      100
    ],
    desktop: {

      /** hodnota pri znizovani hodnoty speed baru input */
      speedBarDecreaseInput: 5,
      /** ako dlho po kliku ma byt speed bar zmrazeny */
      speedBarFreezeFrames: 3,
      /** hodnota pri zvysovani hodnoty speed baru */
      stepAdd: [
        10, 5, 4, 3
      ],

    },
    mobile: {

      /** hodnota pri znizovani hodnoty speed baru input */
      speedBarDecreaseInput: 1,
      /** ako dlho po kliku ma byt speed bar zmrazeny */
      speedBarFreezeFrames: 4,
      /** hodnota pri zvysovani hodnoty speed baru */
      stepAdd: [
        12, 7, 6, 5
      ],

    }

  },

  /** impulse bar veci */
  impulseBar: {

    /** trvanie jednej strany baru pri rovinke */
    barDurationStraight: 20,

    /** trvanie jednej strany baru pri zakrute */
    barDurationCurve: 15,

    /** kvalita pre hlasky */
    qualityPercent: {
      poor: 0,
      good: 51,
      excellent: 81,
      perfect: 99
    },

    /** cas vo framoch za ktory sa ma animovat zmena velkosti baru */
    animationTime: 20,

    /** zakladna sirka baru v px */
    defaultBarWidth: 406

  },

  /** startovacia rychlost */
  startSpeed: 2,

  /** rychlost pri ktorej zacne automaticky klesat */
  cruiseCoef: 3,

  /** pocet framov ako casto ma automaticky klesat */
  speedAutoDecreaseFrames: 3,

  /** hodnota o aku ma automaticky klesat */
  speedAutoDecreaseValue: 0.1,

  /** koeficient spomalovania po prechode cielom */
  finishSlowDownCoef: 0.01,

  /** koeficient spomalovania pri failed start */
  failedStartSlowDownCoef: 0.01,

  /** koeficient zrychlovat pri failed start */
  failedStartAccelerationCoef: 0.1,

  speedCalculationCoefs: {

    topSpeed: {
      low: {
        min: 14,
        multiplier: 2.5
      },
      high: {
        min: 16.5,
        multiplier: 1
      }
    },
    runUp: {
      low: {
        min: 0.03,
        multiplier: 0.02
      },
      high: {
        min: 0.05,
        multiplier: 0.005
      }
    },
    limit: 1000

  },

  /** zobrazenie rychlosti */
  showSpeed: false,

  /** nastavenia pre animacnu rychlost */
  animationSpeedByVelocity: {
    boundary: 0.5,
    valueUnderboundary: 0.5
  }

}
