import { audioHelper } from '@/app/audioHelper/AudioHelper'
import {
  playerAnimationConfig,
  runningPhaseConfig,
  startPhaseConfig
} from '@/app/config'
import {
  AudioNames,
  PlayerAnimationsNames,
  PlayerTypes
} from '@/app/types'
import {
  CallbackAnimationTypes,
  corePhasesManager,
  gsap,
  minigameConfig,
  playersManager
} from '@powerplay/core-minigames'
import { opponent } from './Opponent'

export class OpponentAnimationManager {

  /** Prave beziaca animacia */
  private animationRunning = PlayerAnimationsNames.prepare

  /** ci uz korculuje po rozbehu */
  public isSkating = false

  /** ci sa nachadza v zakrute */
  private isTurning = false

  /** ci uz bola prva zakruta */
  private wasFirstTurn = false

  /** vaha flat animacie */
  private flatWeight = 0

  /** Pocitadlo frameov pre zmenu rychlosti z animacie start do flat */
  private framesCounterAnimationCrossfade = 0

  /** Ci ide o startovanie alebo nie kvoli zvukom */
  private isStarting = false

  /**
   * Start animacie prestart
   */
  public startPrestartAnimation(): void {

    opponent.animationsManager.changeTo(PlayerAnimationsNames.prestart)

  }

  /**
   * Spustenie startovacej animacie
   */
  public startAnimation(): void {

    this.isStarting = true

    opponent.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.start,
      CallbackAnimationTypes.loop,
      () => {

        this.changeAnimationSpeed(startPhaseConfig.animationSpeed)
        if (!this.isSkating) return

        opponent.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.start,
          CallbackAnimationTypes.loop
        )
        this.isStarting = false
        this.startSkatingAnimation()

      }
    )

    opponent.animationsManager.crossfadeTo(PlayerAnimationsNames.start, 0.2, true, false)

    const crossfadeAnimationSpeed = gsap.to({}, {
      duration: 0.2,
      onUpdate: () => {

        const progress = crossfadeAnimationSpeed.progress()
        const startSpeed = 1
        const speed = startSpeed + (startPhaseConfig.animationSpeed - startSpeed) * progress
        this.changeAnimationSpeed(speed)

      }
    })

  }

  /**
   * Spustenie fail startovacej animacie
   */
  public failedStartAnimation(): void {

    opponent.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.falseStart,
      CallbackAnimationTypes.end,
      () => {

        opponent.animationsManager.crossfadeTo(
          PlayerAnimationsNames.falseStartEnd,
          playerAnimationConfig.defaultCrossfadeTime,
          true,
          false
        )
        opponent.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.falseStart,
          CallbackAnimationTypes.end
        )

      }
    )
    opponent.animationsManager.changeTo(PlayerAnimationsNames.falseStart)

  }

  /**
   * Prepinanie medzi animaciami turn a flatPlane
   * @param value - toggle value
   */
  public toggleTurn(value: boolean): void {

    let animation = PlayerAnimationsNames.flatPlane
    this.isTurning = false
    this.wasFirstTurn = true
    if (value) {

      animation = PlayerAnimationsNames.turn
      this.isTurning = true

    }

    this.animationRunning = animation

  }

  /**
   * manualne ovladanie weight animacii zakruty a roviny
   */
  private manualChangingTurnFlat(): void {

    const changingSpeed = runningPhaseConfig.manualCrossfadeAnimationSpeed

    if (this.isTurning) {

      this.flatWeight -= changingSpeed
      if (this.flatWeight < 0) this.flatWeight = 0

    } else {

      this.flatWeight += changingSpeed
      if (this.flatWeight > 1) this.flatWeight = 1

    }

    const anim = this.wasFirstTurn ? PlayerAnimationsNames.turn : PlayerAnimationsNames.start
    opponent.animationsManager.setWeight(anim, 1 - this.flatWeight)
    opponent.animationsManager.setWeight(PlayerAnimationsNames.flatPlane, this.flatWeight)


  }

  /**
   * Spustenie animacie v cieli
   */
  public finishAnimation(): void {


    const time = playersManager.players[0].resultsArr?.[
      corePhasesManager.disciplineActualAttempt - 1
    ].main

    let emotionAnimation = PlayerAnimationsNames.neutral

    if (time === undefined || time >= minigameConfig.dnfValue) {

      emotionAnimation = PlayerAnimationsNames.happy

    }

    this.animationRunning = emotionAnimation

    opponent.animationsManager.setWeight(PlayerAnimationsNames.turn, 0)
    opponent.animationsManager.setWeight(PlayerAnimationsNames.flatPlane, 0)

    if (emotionAnimation === PlayerAnimationsNames.neutral) this.startNeutralAnimation()
    if (emotionAnimation === PlayerAnimationsNames.happy) this.startHappyAnimation()

  }

  /**
   * Spustenie animacie neutral
   */
  private startNeutralAnimation(): void {

    opponent.animationsManager.changeTo(PlayerAnimationsNames.neutral)

  }

  /**
   * Spustenie animacie happy
   */
  private startHappyAnimation(): void {

    opponent.animationsManager.changeTo(PlayerAnimationsNames.happy)

  }

  /**
   * Start skatingu
   */
  private startSkatingAnimation(): void {

    this.framesCounterAnimationCrossfade = 0
    this.animationRunning = PlayerAnimationsNames.flatPlane

    // musime este nastavit rovnaky cas flat animacii podla startu
    opponent.animationsManager.manualyUpdateTimeByPercent(
      PlayerAnimationsNames.flatPlane,
      opponent.animationsManager.getAnimationPercentageDone(PlayerAnimationsNames.start)
    )

  }

  /**
   * Zmena rychlosti animacii
   * @param speed - Nova rychlost animacii
   */
  private changeAnimationSpeed(speed: number) {

    opponent.animationsManager.setSpeed(speed)

  }

  /**
   * Update loop
   */
  public update(): void {

    // startovaci zvuk klopkania
    if (this.isStarting) {

      audioHelper.manageSkateHitIceSound(
        AudioNames.skateStartLeftOpponent,
        AudioNames.skateStartRightOpponent,
        opponent.animationsManager.getAnimationActualTime(PlayerAnimationsNames.start),
        PlayerTypes.opponent
      )

    }

    const animations = [PlayerAnimationsNames.flatPlane, PlayerAnimationsNames.turn]
    if (animations.includes(this.animationRunning)) {

      this.manualChangingTurnFlat()

      // najskor si iba vypocitame, aka rychlost by mala byt
      const speed = (opponent.speedManager.percentSpeed < 0.5) ?
        0.5 :
        opponent.speedManager.percentSpeed

      let finalSpeed = speed

      // na zaciatku ked ideme z animacie start do flat, potrebujeme crossfade aj pre rychlost
      if (this.flatWeight < 1 && !this.wasFirstTurn) {

        const { animationSpeed } = startPhaseConfig
        const maxFrames = runningPhaseConfig.manualCrossfadeAnimationSpeed * 100
        this.framesCounterAnimationCrossfade++
        const perc = this.framesCounterAnimationCrossfade / maxFrames
        finalSpeed = animationSpeed - ((animationSpeed - speed) * perc)

      }

      this.changeAnimationSpeed(finalSpeed)

      // zvuk korcul o lad
      audioHelper.manageSkateHitIceSound(
        this.isTurning ? AudioNames.skateCurveLeftOpponent : AudioNames.skateLeftOpponent,
        this.isTurning ? AudioNames.skateCurveRightOpponent : AudioNames.skateRightOpponent,
        opponent.animationsManager.getAnimationActualTime(this.animationRunning),
        PlayerTypes.opponent
      )

    }

  }

  /**
   * Resetovanie
   */
  public reset(): void {

    console.warn('reset')
    this.isSkating = false

    opponent.animationsManager.changeTo(PlayerAnimationsNames.prepare)
    opponent.animationsManager.removeAnimationCallback(
      PlayerAnimationsNames.start,
      CallbackAnimationTypes.loop
    )
    opponent.animationsManager.removeAnimationCallback(
      PlayerAnimationsNames.falseStart,
      CallbackAnimationTypes.end
    )

    this.animationRunning = PlayerAnimationsNames.prepare

  }

}
