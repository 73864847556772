import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'

export const spriteAudio: AudioObject[] = [
  {
    files: [
      AudioNames.spriteLooped
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: true,
    category: AudioCategories.sprite,
    sprite: {
      'audience_noise_var_02': [
        0,
        10000
      ],
      'audience_sad': [
        11000,
        10008.004535147393
      ],
      'audience_yay2': [
        23000,
        8437.551020408166
      ]
    }
  },
  {
    files: [
      AudioNames.spriteNotLooped
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    category: AudioCategories.sprite,
    sprite: {
      'false_start_shots': [
        0,
        3011.337868480726
      ],
      'last_round_bell_v2': [
        5000,
        2400.0000000000005
      ],
      'vo_ready': [
        9000,
        2207.8231292517003
      ]
    }
  },
  {
    files: [
      AudioNames.spriteMovementRightOpponent
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 0.2,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    category: AudioCategories.sprite,
    group: AudioGroups.movementOpponent,
    sprite: {
      'ice_skating_curve_right_opponent': [
        0,
        567.5283446712018
      ],
      'ice_skating_right_opponent': [
        2000,
        623.628117913832
      ],
      'ice_skating_runup_right_opponent': [
        4000,
        551.4965986394556
      ]
    }
  },
  {
    files: [
      AudioNames.spriteMovementLeftOpponent
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 0.2,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    category: AudioCategories.sprite,
    group: AudioGroups.movementOpponent,
    sprite: {
      'ice_skating_curve_left_opponent': [
        0,
        454.2857142857143
      ],
      'ice_skating_left_opponent': [
        2000,
        454.28571428571416
      ],
      'ice_skating_runup_left_opponent': [
        4000,
        511.74603174603203
      ]
    }
  },
  {
    files: [
      AudioNames.spriteMovementRightPlayer
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    category: AudioCategories.sprite,
    group: AudioGroups.movementPlayer,
    sprite: {
      'ice_skating_curve_right_player': [
        0,
        567.5283446712018
      ],
      'ice_skating_right_player': [
        2000,
        623.628117913832
      ],
      'ice_skating_runup_right_player': [
        4000,
        551.4965986394556
      ]
    }
  },
  {
    files: [
      AudioNames.spriteMovementLeftPlayer
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    category: AudioCategories.sprite,
    group: AudioGroups.movementPlayer,
    sprite: {
      'ice_skating_curve_left_player': [
        0,
        454.2857142857143
      ],
      'ice_skating_left_player': [
        2000,
        454.28571428571416
      ],
      'ice_skating_runup_left_player': [
        4000,
        511.74603174603203
      ]
    }
  },
  {
    files: [
      AudioNames.spriteCommentators
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    ignoreModes: [3, 14, 9, 10],
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    group: AudioGroups.commentators,
    subset: true,
    sprite: {
      'finish_1': [
        0,
        5915.986394557823
      ],
      'finish_2': [
        7000,
        5312.063492063492
      ],
      'finish_3': [
        14000,
        3615.351473922903
      ],
      'finish_4': [
        19000,
        5132.517006802722
      ],
      'VO_after_start_01': [
        26000,
        3514.557823129252
      ],
      'VO_after_start_02': [
        31000,
        3619.04761904762
      ],
      'VO_after_start_03': [
        36000,
        3645.1700680272124
      ],
      'VO_after_start_04': [
        41000,
        3801.9047619047656
      ],
      'VO_split_time_bad': [
        46000,
        2757.0068027210864
      ],
      'VO_split_time_bad2': [
        50000,
        4089.251700680272
      ],
      'VO_split_time_close': [
        56000,
        3148.843537414969
      ],
      'VO_split_time_close2': [
        61000,
        4481.088435374148
      ],
      'VO_split_time_lead': [
        67000,
        3540.680272108844
      ],
      'VO_split_time_lead2': [
        72000,
        3932.5170068027264
      ]
    }
  }
]