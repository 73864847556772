import type { Module } from 'vuex'
import type { RootState } from '../index'
import {
  minigameConfig,
  type SplitTimeData
} from '@powerplay/core-minigames'

export interface SplitTimeState {
  splitTimes: string[],
  actualTime: string,
  splitTimeData: SplitTimeData[],
  showFinalLap: boolean,
  showSplitTimes: boolean,
  showLeader: boolean,
  bestTime: string,
  pb: string,
  wr: number,
  finalTimes: string[],
  showFinishSplit: boolean
}

const initialState = () => ({
  splitTimes: [],
  actualTime: '',
  splitTimeData: [],
  showFinalLap: false,
  showSplitTimes: false,
  showLeader: false,
  bestTime: '',
  pb: '',
  wr: minigameConfig.dnfValue,
  finalTimes: [],
  showFinishSplit: false
})

const splitTimeState: Module<SplitTimeState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getSplitTimesState: (moduleState: SplitTimeState) => moduleState,
    getSplitTimes: (moduleState: SplitTimeState) => moduleState.splitTimes,
    getActualTime: (moduleState: SplitTimeState) => moduleState.actualTime,
    getSplitTimeData: (moduleState: SplitTimeState) => moduleState.splitTimeData,
    getShowFinalLap: (moduleState: SplitTimeState) => moduleState.showFinalLap,
    getBestTime: (moduleState: SplitTimeState) => moduleState.bestTime,
    getShowLeader: (moduleState: SplitTimeState) => moduleState.showLeader,
    getPb: (moduleState: SplitTimeState) => moduleState.pb,
    getWr: (moduleState: SplitTimeState) => moduleState.wr,
    getShowSplitTimes: (moduleState: SplitTimeState) => moduleState.showSplitTimes,
    getShowFinishSplit: (moduleState: SplitTimeState) => moduleState.showFinishSplit,
    getFinalTimes: (moduleState: SplitTimeState) => moduleState.finalTimes
  },

  mutations: {
    RESET: (moduleState: SplitTimeState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: SplitTimeState, newState: SplitTimeState) => {

      moduleState.splitTimes = newState.splitTimes

    },
    ADD_SPLIT_TIME: (moduleState: SplitTimeState, splitTime: string) => {

      moduleState.splitTimes.push(splitTime)

    },
    SET_ACTUAL_TIME: (moduleState: SplitTimeState, actualTime: string) => {

      moduleState.actualTime = actualTime

    },
    ADD_SPLIT_TIME_DATA: (moduleState: SplitTimeState, splitTimeData: SplitTimeData) => {

      moduleState.splitTimeData.push(splitTimeData)

    },
    ADD_SPLIT_TIME_DATA_BEFORE_LAST: (
      moduleState: SplitTimeState,
      splitTimeData: SplitTimeData
    ) => {

      const length = moduleState.splitTimeData.length
      const lastSplitTimeData = moduleState.splitTimeData[length - 1]
      moduleState.splitTimeData[length - 1] = splitTimeData
      moduleState.splitTimeData.push(lastSplitTimeData)

    },
    ADD_FINAL_TIME: (moduleState: SplitTimeState, time: string) => {

      moduleState.finalTimes.push(time)
      console.log(time, moduleState.finalTimes)
      moduleState.showFinishSplit = true

    },
    ADD_FINAL_TIME_BEFORE_LAST: (moduleState: SplitTimeState, time: string) => {

      const length = moduleState.finalTimes.length
      const lastFinalTimesData = moduleState.finalTimes[length - 1]
      moduleState.finalTimes[length - 1] = time
      moduleState.finalTimes.push(lastFinalTimesData)

      moduleState.finalTimes.push(time)
      console.log(time, moduleState.finalTimes)
      moduleState.showFinishSplit = true

    },
    CHANGE_SPLIT_TIME_DATA: (moduleState: SplitTimeState) => {

      const temp = moduleState.splitTimeData[2]
      moduleState.splitTimeData[2] = moduleState.splitTimeData[3]
      moduleState.splitTimeData[3] = temp

    },
    SET_FINAL_LAP: (moduleState: SplitTimeState, showFinalLap: boolean) => {

      moduleState.showFinalLap = showFinalLap

    },
    SET_SHOW_SPLIT_TIMES: (moduleState: SplitTimeState, show: boolean) => {

      moduleState.showSplitTimes = show

    },
    SET_SHOW_FINISH_SPLIT: (moduleState: SplitTimeState, show: boolean) => {

      moduleState.showFinishSplit = show

    },
    SET_SHOW_LEADER: (moduleState: SplitTimeState, show: boolean) => {

      moduleState.showLeader = show

    },
    SET_BEST_TIME: (moduleState: SplitTimeState, bestTime: string) => {

      moduleState.bestTime = bestTime

    },
    SET_PB: (moduleState: SplitTimeState, pb: string) => {

      moduleState.pb = pb

    },
    SET_WR: (moduleState: SplitTimeState, wr: number) => {

      moduleState.wr = wr

    }
  }
}

export default splitTimeState
