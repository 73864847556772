/** Stavy startu */
export enum StartQuality {

  perfect = 'perfect',
  excelent = 'excelent',
  good = 'good',
  poor = 'poor',
  failed = 'failed'

}
