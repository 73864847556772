import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/*
 * pozicia poslednej branky pre config veci nizsie
 * const lastGate = batchingConfig.get('Branka0')
 * const lastGateData = lastGate ? lastGate.data : [{ offset: new THREE.Vector3() }]
 * const lastGatePosition = lastGateData[lastGateData.length - 1].offset.clone()
 */

/**
 * Konfig pre kameru pre vsetky stavy
 */
export const cameraConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(95.7000, 14.63113, -0.585197),
      endPosition: new THREE.Vector3(13.30098, 10.09447, -0.27886),
      startRotation: new THREE.Vector3(1.572647, 0.0862535, -1.592212),
      endRotation: new THREE.Vector3(1.447413, -0.0848926, -0.86645),
      duration: 5,
      notSkippableFrames: 20,
      fov: 63.7
    },
    [CameraStates.intro2]: {
      startPosition: new THREE.Vector3(-43.2328, 3.5972, 35.3094),
      endPosition: new THREE.Vector3(-41.9679, 3.9032, 24.6302),
      startRotation: new THREE.Vector3(1.5068, 0.1912, -1.2457),
      endRotation: new THREE.Vector3(1.6273, 0.1632, -1.9058),
      duration: 3,
      notSkippableFrames: 20,
      fov: 63.7
    },
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(-1, 8, 35),
      startLookAt: new THREE.Vector3(0, 8, -35),
      endPosition: new THREE.Vector3(1, 8, 35),
      duration: 5
    },
    [CameraStates.disciplineIntro]: [
      {
        startPosition: new THREE.Vector3(-73.6640, 1.4185, 30.4304),
        endPosition: new THREE.Vector3(-59.2592, 1.1757, 30.0258),
        startRotation: new THREE.Vector3(1.57038, -0.015797, 1.54168),
        endRotation: new THREE.Vector3(1.57045, -0.0074828, 1.52652),
        duration: 3,
        fov: 63.7
      },
      {
        startPosition: new THREE.Vector3(-52.3911, 1.613132, 32.29635),
        endPosition: new THREE.Vector3(-51.6606, 1.67356, 27.1675),
        startRotation: new THREE.Vector3(1.537412, 0.0964083, -1.237293),
        endRotation: new THREE.Vector3(1.610976, 0.0909967, -1.986737),
        duration: 3,
        fov: 63.7
      }
    ],
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      startPosition: new THREE.Vector3(2, 2, -3),
      endPosition: new THREE.Vector3(-2, 2, -3),
      duration: 7,
      offsetCamera: true
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5

}
