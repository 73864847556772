import {
  pathAssets,
  pathAssetsGlobal
} from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { TexturesNames } from '../types'

// Hlavny priecinok s texturami
const texturesDir = `${pathAssets}/textures/`
const texturesDirGlobal = `${pathAssetsGlobal}/textures/`
texturesDirGlobal // iba kvoli tomu, aby lint nedaval :) ked sa prida prvy global, tak vymazeme

/**
 * Konfig pre textury
 */
export const texturesConfig: LoaderDataTypes = {
  [TexturesNames.skierRaceWhiteMan]: {
    ext: 'ktx2',
    version: 4,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceMulattoMan]: {
    ext: 'ktx2',
    version: 4,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceBlackMan]: {
    ext: 'ktx2',
    version: 5,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceWhiteWoman]: {
    ext: 'ktx2',
    version: 3,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceMulattoWoman]: {
    ext: 'ktx2',
    version: 3,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceBlackWoman]: {
    ext: 'ktx2',
    version: 3,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierClothes]: {
    ext: 'ktx2',
    genderActive: true,
    version: 7,
    femaleVersion: 3,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierClothesOpponent]: {
    ext: 'ktx2',
    genderActive: true,
    version: 5,
    femaleVersion: 3,
    opponent: true,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.hill]: {
    version: 3,
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.ads]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.flags]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.iceTrack]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.lightmapHill]: {
    ext: 'ktx2',
    version: 5,
    dir: `${texturesDir}/hill/`,
    returnTextureLightmap: true
  },
  [TexturesNames.staticPeople]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.audience]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.ground]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.transparentAds]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.opacityGradient]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`,
    version: 9
  },
  [TexturesNames.emissive]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`,
    version: 2
  },
  [TexturesNames.trackLines]: {
    ext: 'ktx2',
    version: 3,
    dir: `${texturesDir}/hill/`
  }
}
