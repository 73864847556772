import {
  audioGameConfig,
  curvesConfig,
  splitTimesConfig
} from '@/app/config'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import {
  CurveType,
  TutorialEventType,
  PlayerTypes,
  AudioNames
} from '@/app/types'
import {
  audioManager,
  timeManager,
  TimesTypes
} from '@powerplay/core-minigames'
import { SplitTimeManager } from '../player/SplitTimeManager'

/**
 * Classa na triggery pre prechod medzi modmi hry
 */
export class TriggersManager {

  /** Index aktualneho triggera */
  private actualIndex = 0

  /** typ nasledovneho triggera */
  private nextCurveTriggerType: CurveType = CurveType.start

  /** semafor na splitTrigger */
  private splitTriggered = false

  /** split time manager */
  public splitTimeManager = new SplitTimeManager()

  /** opponent uuid pre vylucenie na splittimes */
  public opponentUuid = ''

  /** Typ hraca pre trigger */
  private type: PlayerTypes

  /** laneSwitchTrigger triggered */
  private laneSwitchTriggered = false

  /** preFinishTrigger triggered */
  private preFinishTriggered = false

  /** hypeVolumeIncreaseTriggered triggered */
  private hypeVolumeIncreaseTriggered = false

  /** ci uz bolo zvonenie na posledne kolo */
  private lastRoundBellTriggered = false

  /** callback pri start triggeri */
  private triggerCallbackStart!: () => void

  /** callback pri end triggeri */
  private triggerCallbackEnd!: () => void

  /**
   * Konstruktor
   * @param type - Typ hraca pre trigger
   */
  public constructor(type: PlayerTypes) {

    this.type = type

  }

  /**
   * setter
   * @param callback - callback pri start triggeri
   */
  public setStartCallback(callback: () => void): void {

    this.triggerCallbackStart = callback

  }

  /**
   * setter
   * @param callback - callback pri start triggeri
   */
  public setEndCallback(callback: () => void): void {

    this.triggerCallbackEnd = callback

  }

  /**
   * Setter
   * @param value - nova hodnota
   */
  public setNextCurveTriggerType(value: CurveType): void {

    this.nextCurveTriggerType = value

  }

  /**
   * navysenie indexu
   */
  public incrementIndex(): void {

    this.actualIndex += 1

  }

  /**
   * Kontrola prehratia zvuku zvonenia posledneho kola
   * @param actualPercent - Aktualna % hodnota prejdenia trate
   */
  private checkLastRoundBell(actualPercent: number): void {

    const splitPercent = splitTimesConfig.lastRoundBellPosition
    if (!this.lastRoundBellTriggered && actualPercent > splitPercent) {

      audioManager.play(AudioNames.lastRoundBell)
      this.lastRoundBellTriggered = true

    }

  }

  /**
   * Skontrolovanie prejdenia aktualneho triggera
   * @param actualPercent - Aktualna % hodnota prejdenia trate
   * @param lastPercent - Posledna % hodnota prejdenia trate
   * @param oneMeterInPercent - Jeden meter v % prejdenia trate
   * @param opponentCheckpointsPassed - Pole absolvovanych medzicasov supera
   */
  public checkActualTrigger(
    actualPercent: number,
    lastPercent: number,
    oneMeterInPercent: number,
    opponentCheckpointsPassed: boolean[] = []
  ): void {

    if (
      this.type === PlayerTypes.player &&
            !this.laneSwitchTriggered &&
            actualPercent > audioGameConfig.laneSwitchTrigger
    ) {

      this.laneSwitchTriggered = true
      audioManager.play(AudioNames.commentLaneSwitch)

    }
    if (
      this.type === PlayerTypes.player &&
            !this.preFinishTriggered &&
            actualPercent > audioGameConfig.preFinishTrigger
    ) {

      this.preFinishTriggered = true
      audioManager.play(AudioNames.commentBeforeFinish)

    }

    if (
      this.type === PlayerTypes.player &&
            !this.hypeVolumeIncreaseTriggered &&
            actualPercent > audioGameConfig.hypeIncreaseVolumeTrigger
    ) {

      this.hypeVolumeIncreaseTriggered = true
      audioManager.changeAudioVolume(AudioNames.audienceHype, 1)

    }



    this.checkLastRoundBell(actualPercent)

    const splitPercent = splitTimesConfig.splitTimePositions[
      this.splitTimeManager.actualSplitIndex
    ]

    if (lastPercent < splitPercent && actualPercent > splitPercent && !this.splitTriggered) {

      const seconds = timeManager.getSecondsFromFrames(timeManager.getFrames(TimesTypes.game), 3)

      this.splitTriggered = true

      const finalSeconds = this.splitTimeManager.calculateFinalSeconds(
        seconds,
        actualPercent,
        lastPercent,
        splitPercent,
        oneMeterInPercent,
        false,
        this.type,
      )

      this.splitTimeManager.checkActualSplit(
        finalSeconds,
        this.type,
        this.opponentUuid,
        false,
        opponentCheckpointsPassed
      )

    }

    if (
      this.nextCurveTriggerType === CurveType.start &&
            actualPercent > curvesConfig.curvesPlayer.start[this.actualIndex]
    ) {

      tutorialFlow.eventActionTrigger(TutorialEventType.startOfSecondCurve)
      this.triggerCallbackStart()
      return

    }

    if (
      this.nextCurveTriggerType === CurveType.end &&
            actualPercent > curvesConfig.curvesPlayer.end[this.actualIndex]
    ) {

      this.triggerCallbackEnd()

    }

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.actualIndex = 0
    this.nextCurveTriggerType = CurveType.start
    this.splitTriggered = false
    this.splitTimeManager = new SplitTimeManager()
    this.laneSwitchTriggered = false
    this.preFinishTriggered = false
    this.hypeVolumeIncreaseTriggered = false
    this.lastRoundBellTriggered = false

  }

}
