export const curvesConfig = {

  /** %/100 bodov kde sa zacina a konci zakruta hraca */
  curvesPlayer: {
    start: [
      0.225,
      0.625
    ],
    end: [
      0.42,
      0.8
    ]
  },

  /** %/100 bodov kde sa zacina a konci zakruta protihraca */
  curvesOpponent: {
    start: [
      0.225,
      0.625
    ],
    end: [
      0.42,
      0.8
    ]
  }

}
