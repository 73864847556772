import {
  opponentConfig,
  runningPhaseConfig,
  velocityConfig
} from '@/app/config'
import type { SpeedManager } from '@/app/SpeedManager/SpeedManager'

/**
 * Running impulse manager
 * Trieda, ktora sa stara o ovladanie pri korculovani opponenta
 */
export class RunningImpulseManager {

  /** pocitadlo framov */
  private frameCounter = -1

  /** trvanie jednej strany impulzu */
  private barDuration = velocityConfig.impulseBar.barDurationStraight

  /** ci sa pocas aktualneho impulzu zmenila dlzka strany */
  private durationSwitched = false

  /** ci sme v zakrute */
  private isCurve = false

  /** ako dlho je klik zablokovany */
  private clickBlockedFrames = 0

  /** ci je klik zablokovany */
  private isBarBlocked = false

  /**
   * Update metoda ktoru mame pouzit externe
   */
  public update(speedManager: SpeedManager): void {

    if (this.isBarBlocked) {

      this.clickBlockedFrames++
      if (this.clickBlockedFrames % runningPhaseConfig.changeFreezeDuration) return
      this.isBarBlocked = false

    }

    this.frameCounter += 1

    if (this.frameCounter % velocityConfig.speedAutoDecreaseFrames === 0 &&
             this.frameCounter !== 0) {

      speedManager.autodecreaseImpulse()

    }
    if (this.frameCounter % this.barDuration === 0 && this.frameCounter !== 0) {

      this.frameCounter = -1
      this.changeActualImpulse(speedManager)

    }

  }

  /**
   * Prepinanie barDuration podla toho, ci sme alebo nie sme v zakrute
   * @param value - hodnota isCurve
   */
  public setIsCurve(value: boolean): void {

    this.isCurve = value
    this.durationSwitched = true

  }

  /** zmena na dalsie slacenie impulzu */
  public changeActualImpulse(speedManager: SpeedManager): void {

    const from = this.barDuration * opponentConfig.runningImpulseCoef.from
    const to = this.barDuration * opponentConfig.runningImpulseCoef.to

    const randCorrectFrames = Math.round(Math.random() * (to - from) + from)

    speedManager.resolveImpulsePower(this.barDuration, randCorrectFrames)

    this.clickBlockedFrames = 0
    this.isBarBlocked = true

    if (!this.durationSwitched) return

    this.durationSwitched = false
    if (this.isCurve) {

      this.barDuration = velocityConfig.impulseBar.barDurationCurve

    } else {

      this.barDuration = velocityConfig.impulseBar.barDurationStraight

    }

  }

}
