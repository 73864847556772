import { pathAssets } from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { ModelsNames } from '../types'

// Hlavny priecinok s modelmi
const modelsDir = `${pathAssets}/models/`

/**
 * Konfig pre modely
 */
export const modelsConfig: LoaderDataTypes = {
  [ModelsNames.skier]: {
    version: 10,
    femaleVersion: 8,
    ext: 'glb',
    genderActive: true,
    dir: modelsDir,
    mainMeshNames: [ModelsNames.skier],
    opponentCheck: true
  },
  [ModelsNames.hill]: {
    ext: 'glb',
    version: 19,
    dir: modelsDir
  },
  [ModelsNames.trackLines]: {
    ext: 'glb',
    version: 4,
    dir: modelsDir
  }
}
