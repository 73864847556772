import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface UiState {
  showTimeKeeper: boolean,
  showFinishTopBox: boolean,
  showTrainingLayout: boolean,
  isTraining: boolean,
  showBlack: boolean
}

const initialState = () => ({
  showTimeKeeper: false,
  showFinishTopBox: false,
  showTrainingLayout: false,
  isTraining: false,
  showBlack: false
})

const uiState: Module<UiState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getUiState: (moduleState: UiState) => moduleState,
    getShowTimeKeeper: (moduleState: UiState) => moduleState.showTimeKeeper,
    getShowBlack: (moduleState: UiState) => moduleState.showBlack
  },

  mutations: {
    RESET: (moduleState: UiState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: UiState, newState: UiState) => {

      if (moduleState.showTimeKeeper !== undefined) {

        moduleState.showTimeKeeper = newState.showTimeKeeper

      }

      if (moduleState.showFinishTopBox !== undefined) {

        moduleState.showFinishTopBox = newState.showFinishTopBox

      }

      if (moduleState.showTrainingLayout !== undefined) {

        moduleState.showTrainingLayout = newState.showTrainingLayout

      }
      if (moduleState.isTraining !== undefined) {

        moduleState.isTraining = newState.isTraining

      }

    },
    SET_FINISH_TOP_BOX_VISIBILITY: (moduleState: UiState, visible: boolean) => {

      moduleState.showFinishTopBox = visible

    },
    SET_BLACK_SCREEN: (moduleState: UiState, showBlack: boolean) => {

      moduleState.showBlack = showBlack

    },
    SET_TIME_VISIBILITY: (moduleState: UiState, showTimeKeeper: boolean) => {

      moduleState.showTimeKeeper = showTimeKeeper

    }
  }
}

export default uiState
