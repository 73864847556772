import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface SprintState {
  isSprinting: boolean,
  tutorialFreeze: boolean
}

const initialState = () => ({
  isSprinting: false,
  tutorialFreeze: false
})

const sprintState: Module<SprintState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getInputsState: (moduleState: SprintState) => moduleState,
    isSprint: (moduleState: SprintState) => moduleState.isSprinting,
    isFreezedInTutorial: (moduleState: SprintState) => moduleState.tutorialFreeze
  },

  mutations: {
    RESET: (moduleState: SprintState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: SprintState, newState: SprintState) => {

      moduleState.isSprinting = newState.isSprinting

      if (newState.tutorialFreeze !== undefined) {

        moduleState.tutorialFreeze = newState.tutorialFreeze

      }

    }
  }
}

export default sprintState
