import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'
import { spriteAudio } from './spriteAudioConfig'
/**
 * Trieda konfigu pre zvuky
 */
export const audioConfig: AudioObject[] = [

  ...spriteAudio,
  {
    files: [
      AudioNames.audienceHype
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: true,
    category: AudioCategories.audience,
    version: 4
  },

  {
    files: [
      AudioNames.startShot
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.startend
  },

  {
    files: [
      AudioNames.commentBeforeFinish,
      AudioNames.commentBeforeFinish2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.commentators,
    loadRandomCount: 1,
    group: AudioGroups.commentators,
    ignoreModes: [3, 14, 9, 10]
  },

  {
    files: [
      AudioNames.commentFinalResults,
      AudioNames.commentFinalResults2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.commentators,
    group: AudioGroups.commentators,
    loadRandomCount: 1,
    ignoreModes: [3, 14, 9, 10]
  },

  {
    files: [
      AudioNames.commentIntro,
      AudioNames.commentIntro2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.commentators,
    loadRandomCount: 1,
    group: AudioGroups.commentators,
    ignoreModes: [9, 10]
  },

  {
    files: [
      AudioNames.commentLaneSwitch,
      AudioNames.commentLaneSwitch2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.commentators,
    loadRandomCount: 1,
    group: AudioGroups.commentators,
    ignoreModes: [3, 14, 9, 10]
  }

]
